import React from 'react';
import styled from '@emotion/styled';
import { lighten } from 'polished';

import { unit, flexCenter, smallText } from '../styles';
import { ITheme } from '../data-layer/types';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: 'transparent' | 'grey' | 'link';
  theme: ITheme;
}

export default styled('button')(flexCenter, smallText, ({ variant, theme }: IButtonProps) => {
  let backgroundColor;
  let color;
  switch (variant) {
    case 'transparent':
      backgroundColor = 'transparent';
      color = theme.textColor;
      break;
    case 'grey':
      backgroundColor = theme.passiveColor;
      color = theme.textColor;
      break;
    case 'link':
      backgroundColor = 'transparent';
      color = theme.mainColor;
      break;
    default:
      backgroundColor = theme.mainColor;
      color = theme.backgroundColor;
      break;
  }
  return {
    minWidth: variant === 'link' ? 'auto' : `${unit * 20}px`,
    padding: variant === 'link' ? 0 : `9px ${unit * 2.5}px`,
    border: 'none',
    borderRadius: unit * 10,
    fontFamily: 'inherit',
    textDecoration: 'none',
    backgroundColor,
    cursor: 'pointer',
    outline: 'none',
    color,
    transition: 'all 0.3s ease-in-out',
    ':not([:disabled]):hover': {
      backgroundColor: backgroundColor ? lighten(0.1, backgroundColor) : 'transparent',
    },
    ':active': {
      backgroundColor: backgroundColor ? lighten(0.2, backgroundColor) : 'transparent',
    },
    '&[disabled]': {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
  };
});
