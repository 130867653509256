import { ApolloQueryResult } from 'apollo-boost';
import { GetAppointmentsVariables } from 'data-layer/queries/__graphql__/GetAppointments';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { IVisit } from 'data-layer/types';
import { useContext, useEffect, useMemo } from 'react';
import { ClientContext } from './ClientContext';
import { PostMessageProcessing } from './postmessage';

export const usePostMessage = ({
  refetchApp,
  visit,
}: {
  refetchApp?: (
    variables?: GetAppointmentsVariables | undefined,
  ) => Promise<ApolloQueryResult<unknown>>;
  visit?: IVisit;
}): void => {
  const clientContext = useContext(ClientContext);

  const client = clientContext.clientInfo;
  const cred = useMemo<UserCred>(
    () => ({
      user: clientContext.user,
      token: clientContext.token,
      expires: clientContext.expires,
    }),
    [clientContext.user, clientContext.token, clientContext.expires],
  );

  const pmConfig = useMemo(
    () => ({
      client,
      refetchApp,
      cred,
      visit,
    }),
    [client, refetchApp, cred, visit],
  );

  useEffect(() => {
    const pm = new PostMessageProcessing(pmConfig);
    pm.subscribe();
    return () => {
      pm.unsubscribe();
    };
  }, [pmConfig]);
};
