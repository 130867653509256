import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { size } from 'polished';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ITheme } from '../data-layer/types';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled(Logo)(size(64), (props: { theme: ITheme }) => ({
  display: 'block',
  margin: 'auto',
  fill: props.theme.passiveColor,
  path: {
    transformOrigin: 'center',
    // animation: `${spin} 1s linear infinite`,
  },
}));

export default Loading;
