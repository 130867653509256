import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import moment from 'moment';
import ReactDatePicker from 'react-date-picker';

import { ReactComponent as IconCalendar } from '../assets/icon-calendar.svg';
import { ReactComponent as IconError } from '../assets/icon-error.svg';
import { ClientContext, constants } from '../utils';
import { breakpointSmall, unit } from '../styles';
import { ITheme } from '../data-layer/types';

interface DatepickerProps {
  selected: string;
  error: boolean;
  onDateChange: (dateStr: string) => void;
}

const Datepicker: React.FC<DatepickerProps> = ({
  selected,
  error,
  onDateChange,
}: DatepickerProps) => {
  const { lang, theme } = useContext(ClientContext);
  const selectedDate = moment(selected, constants.DATE_FORMAT).toDate();
  const [date, setDate] = useState<Date | null>(selectedDate);
  const onChangeHandler = (dateVal: Date | Date[]) => {
    if (!Array.isArray(dateVal)) {
      setDate(dateVal);
      onDateChange(moment(dateVal).format(constants.DATE_FORMAT));
    }
  };
  const [locale] = lang.code.split('-');
  return (
    <ReactDatePickerStyled
      theme={theme}
      value={date}
      locale={locale}
      calendarIcon={error ? <StyledIconError /> : <StyledIconCalendar theme={theme} />}
      clearIcon={null}
      onChange={onChangeHandler}
    />
  );
};

export default Datepicker;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const iconStyles = css({
  pointerEvents: 'none',
});

const StyledIconCalendar = styled(IconCalendar)(iconStyles, (props: { theme: ITheme }) => ({
  path: {
    fill: props.theme.mainColor,
    stroke: props.theme.mainColor,
  },
}));
const StyledIconError = styled(IconError)(iconStyles);

const ReactDatePickerStyled = styled(ReactDatePicker)((props: { theme: ITheme }) => ({
  width: '100%',
  maxWidth: unit * 29.2,
  '.react-date-picker': {
    '&__inputGroup': {
      '&__input, &__divider': {
        color: props.theme.textColor,
      },
    },
    '&__inputGroup > input:not(.react-date-picker__inputGroup__input)': {
      display: 'none',
    },
    '&__wrapper': {
      padding: `${unit * 1.1}px ${unit * 2.4}px`,
      border: `1px solid transparent`,
      borderRadius: unit * 10,
      fontSize: 26,
      lineHeight: 1.153,
      backgroundColor: props.theme.passiveColor,
      transition: 'all 0.3s ease-in-out',
      [`[dir="ltr"] &`]: {
        paddingRight: unit * 1.5,
      },
      [`[dir="rtl"] &`]: {
        paddingLeft: unit * 1.5,
      },
      '&.react-date-picker--open': {
        borderColor: props.theme.mainColor,
      },
      '.react-date-picker__button': {
        outline: 'none',
      },
    },
    '&__calendar': {
      maxWidth: unit * 29,
      '&--open': {
        [`@media screen and (max-width: ${breakpointSmall}px)`]: {
          top: 'auto !important',
          bottom: '100% !important',
          height: 'auto !important',
        },
      },
    },
  },
  '.react-calendar': {
    borderColor: props.theme.mainColor,
    borderRadius: unit,
    '&__navigation__label': {
      whiteSpace: 'nowrap',
    },
  },
}));
