/* eslint-disable react/require-default-props */
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import Button from './button';
import Caret from './Caret';
import { breakpointLarge, unit } from '../styles';
import { ReactComponent as IconError } from '../assets/icon-error.svg';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

interface BottomFilterItemProps {
  title: string;
  selected?: string;
  onReset?: () => void;
  children?: React.ReactNode;
}

export const BottomFilterItem: React.FC<BottomFilterItemProps> = ({
  title,
  selected,
  onReset,
  children,
}: BottomFilterItemProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { theme } = useContext(ClientContext);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleReset = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    if (onReset) {
      onReset();
    }
  };
  return (
    <Container onClick={toggleDropdown} style={dropdownVisible ? { zIndex: 2 } : undefined}>
      <ButtonStyled theme={theme} variant="link">
        {selected && <IconErrorStyled theme={theme} onClick={handleReset} />}
        {selected || title}
        <Caret />
      </ButtonStyled>
      {dropdownVisible && children}
    </Container>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  button: {
    position: 'relative',
    zIndex: 1,
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    position: 'relative',
  },
});

const ButtonStyled = styled(Button)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontSize: unit * 1.6,
  },
});

const IconErrorStyled = styled(IconError)((props: { theme: ITheme }) => ({
  width: unit * 1.25,
  height: unit * 1.25,
  flexShrink: 0,
  [`[dir="ltr"] &`]: {
    marginRight: unit,
  },
  [`[dir="rtl"] &`]: {
    marginLeft: unit,
  },
  path: {
    stroke: props.theme.mainColor,
  },
}));
