import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';

import { SetNewPasswordForm, Loading } from '../components';
import * as ChangePassword from './__graphql__/ChangePassword';
import * as getChangePassDataType from './__graphql__/getChangePassData';
import { ClientContext } from '../utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function SetNewPassword() {
  const { theme } = useContext(ClientContext);
  const [changePassError, setChangePassError] = useState(false);
  const CHANGE_PASSWORT = gql`
    mutation ChangePassword($id: String!, $currentPassword: String!, $password: String!) {
      changePassword(id: $id, currentPassword: $currentPassword, password: $password) @client
    }
  `;

  const CHANGE_PASS_DATA = gql`
    query getChangePassData {
      smsCode @client
      user @client
    }
  `;

  const passData = useQuery<getChangePassDataType.getChangePassData>(CHANGE_PASS_DATA);

  const [changePassword, { loading, error }] = useMutation<
    ChangePassword.ChangePassword,
    ChangePassword.ChangePasswordVariables
  >(CHANGE_PASSWORT, {
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
    onCompleted(data) {
      setChangePassError(!data.changePassword);
    },
  });
  const changePasswordHandler = (password: string) => {
    if (!passData.data) {
      return;
    }
    setChangePassError(false);
    const { user, smsCode } = passData.data;
    // eslint-disable-next-line consistent-return
    return changePassword({
      variables: {
        password,
        id: user || '',
        currentPassword: smsCode || '',
      },
    });
  };
  if (loading) return <Loading theme={theme} />;
  return (
    <SetNewPasswordForm changePassword={changePasswordHandler} error={!!error || changePassError} />
  );
}
