import React, { useContext } from 'react';
import styled from '@emotion/styled';

import Button from './button';
import { unit } from '../styles';
import { handleOpenWidgetClick } from 'utils/utils';
import { ClientContext } from '../utils';

interface INewRecordItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  uniqKey: string;
  type: string;
  children?: React.ReactNode;
}

const NewRecordItem = ({ uniqKey, type, children }: INewRecordItemProps): JSX.Element => {
  const { theme } = useContext(ClientContext);
  const onClickHandler = () => {
    handleOpenWidgetClick({ key: uniqKey, type });
  };
  return (
    <Item theme={theme} variant="grey" onClick={onClickHandler}>
      <Inner>{children}</Inner>
    </Item>
  );
};

NewRecordItem.defaultProps = {
  children: undefined,
};

export default NewRecordItem;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

export const Item = styled(Button)({
  whiteSpace: 'nowrap',
  width: '100%',
  padding: unit * 1.2,
  '&:not(:last-child)': {
    marginBottom: unit * 2,
  },
});

const Inner = styled('span')({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
