import { gql } from 'apollo-boost';

export const GET_EHR_DIAGNOSTICS = gql`
  query GetEHRDiagnostics(
    $businessID: String!
    $clientId: String!
    $patientId: String!
    $user: String!
    $token: String!
    $offset: Int
    $size: Int
    $ehrEndPoint: String!
  ) {
    getEHRDiagnostics(
      businessID: $businessID
      clientId: $clientId
      patientId: $patientId
      user: $user
      token: $token
      offset: $offset
      size: $size
      ehrEndPoint: $ehrEndPoint
    ) @client {
      diagnostics {
        id
        status
        business {
          id
          name
          location
          networkId
        }
        patientId
        patient {
          id
          surname
          name
          middleName
          phones
          email
          gender
          date
          medcardNumber
        }
        type
        effectivePeriod {
          begin
          end
        }
        issuedDate
        result {
          id
          createdDate
          type
          observationKey
          effectivePeriod {
            begin
            end
          }
          issuedDate
          performerDoctor {
            id
            name
            surname
          }
          performerBusiness {
            name
            location
          }
          value {
            serializedValue
            code
            value
          }
          note
          interpretation
          ranges {
            low
            high
            type
            age {
              begin
              end
            }
            text
          }
          components {
            type
            value {
              serializedValue
              code
              value
            }
            interpretation
            ranges {
              low
              high
              type
              age {
                begin
                end
              }
              text
            }
          }
        }
        resultInterpreter {
          id
          name
          surname
        }
        resultInterpretation
        imagineMedia
        attachments {
          url
          file
        }
        services {
          name
          id
        }
        category
        attachments {
          url
          file
        }
      }
    }
  }
`;
