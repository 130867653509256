import { IAppointmentOptions, IBusinessProfile } from '../types';

export function mapBusinessToAppointmentOptions(business: IBusinessProfile): IAppointmentOptions {
  const availableCategories = business.taxonomies.filter((t) => t.taxonomyType === 'CATEGORY');
  const availableServices = business.taxonomies.filter((t) => t.taxonomyType === 'SERVICE');

  return {
    businessId: business.id,
    availableCategories,
    availableServices,
    availableResources: business.resources,
  };
}
