import moment from 'moment';
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import { ReactComponent as IconFile } from '../assets/icon-file.svg';
import { breakpointSmall, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { ITheme, IEHRAppointmentCard } from '../data-layer/types';
import { ClientContext } from '../utils';
import { useTranslation } from 'react-i18next';

type cardVariant = 'withLink' | 'withoutLink' | undefined;

export const EHRAppointmentBlock: React.FC<IEHRAppointmentCard> = React.memo(
  (props: IEHRAppointmentCard) => {
    const { t } = useTranslation();
    const { theme } = useContext(ClientContext);
    const { appointmentData, path } = props;
    const { start, doctor, services, attachments, business } = appointmentData;
    const queryString = urlManager.getQueryString();
    const cardVariant = path ? 'withLink' : 'withoutLink';

    const startTime = moment.utc(start).calendar(null, { sameElse: 'D MMMM YYYY HH:mm' });
    function getWorkerName () {
      const { name, surname } = doctor;
      return `${surname} ${name}`;
    }

    function getTitle () {
      const workerName = getWorkerName();
      const { specialization } = doctor
      const title = specialization

      return `${title} ${workerName}`.trim();
    }
    function getServices () {
      return services?.length ? services[0].name : ''
    }

    function handleDownloadClick (e: React.MouseEvent<SVGSVGElement>, attach: { url: string, file: string }) {
      const { url } = attach
      window.open(url, '_blank');
      e.preventDefault()
    }

    function getBusnessName () {
      return urlManager.getNetworkId() && business?.name ? <CardDate>{business?.name}</CardDate> : <></>
    }

    function getAttachment (attach: { url: string, file: string }) {
      const { file } = attach
      const title = `${t('screens.ehr.download')} ${file}`
      return <Download key={file} title={title}><IconFile onClick={(e) => handleDownloadClick(e, attach)} height={25} width={25} /></Download>
    }

    function getAttachments () {
      if (!attachments.length) {
        return <></>
      }
      return attachments.map(getAttachment)
    }

    function getCartContent () {
      if (path) {
        return (
          <StyledLink to={`${path}?${queryString}`}>
            {getStyledLinkContent()}
          </StyledLink>
        );
      }
      return (
        <StyledNoLink>
          {getStyledLinkContent()}
        </StyledNoLink>
      );
    }

    function getStyledLinkContent () {
      return (
        <>
          {getBusnessName()}
          <CardTitle theme={theme} title={getTitle()}>
            {getTitle()}
          </CardTitle>
          <CardDate>{startTime}</CardDate>
          {getServices()}
          {getAttachments()}
        </>
      )
    }

    return (
      <Card variant={cardVariant} theme={theme}>
        {getCartContent()}
      </Card>
    );
  },
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: cardVariant;
  theme: ITheme;
}

const Card = styled('div')(({ variant, theme }: ICardProps) => {
  let background;
  switch (variant) {
    case 'withLink':
      background = '#fff';
      break;
    // case 'withoutLink':
    //   background = theme.telemedItemBackgroundColor;
    //   break;
    default:
      background = theme.pastItemBackgroundColor;
      break;
  }
  return {
    background,
    boxShadow: '3px 3px 6px 3px rgb(12, 6, 21, .09)',
    borderRadius: unit * 1.5,
    padding: `${unit * 3}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')((props: { theme: ITheme }) => ({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  color: props.theme.textColor,
  margin: '0',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textTransform: 'capitalize'
}));

const CardDate = styled('div')({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '17px',
});

const CardResource = styled('h5')({
  display: 'flex',
  alignItems: 'center',
  margin: 'auto 0 0',
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    margin: 0,
  },
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});

const StyledNoLink = styled('div')({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});

const Download = styled('div')({
  color: '#47C4E4',
  margin: '0 3px'
});
