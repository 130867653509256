/**
 * Default translation for 'ar_PS' language
 */

// tslint:disable-next-line:variable-name
export const ar_PS = {
  resource: 'طبيب',
  button: {
    ehr: 'EMR',
    back: 'الى الخلف'
  },
  common: {
    picker: {
      doneBtnLabel: 'اختار',
      cancelBtnLabel: 'الغاء',
    },
    currency: {
      NIS: 'USD',
      RUB: 'RUR',
      RUR: 'RUR',
      ILS: 'ILS',
    },
    priceType: {
      equal: '',
      begin_with: 'ابتداء من',
      average: '',
      end: 'to',
    },
    errors: {
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_MISMATCH:
        'عذرًا ، لقد تم أخذ هذا الموعد الزمني بالفعل. من فضلك ، اختر واحدة أخرى.',
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_UNAVAILABLE:
        'الطبيب المختار لم يعد يقدم هذه الخدمة. رجاءا، اختر طبيب اخر',
      RPC_ERROR__APPOINTMENT_CONFIRM_FAILED: 'Selected time is taken',
      RPC_ERROR__APPOINTMENT_CLIENT_OVERLAPPED:
        'You have already taken this time. Please try booking another one',
      RPC_ERROR__APPOINTMENT_CABINET_OVERLAPPED:
        'The cabinet you have selected is already taken. Please try booking another cabinet',
      RPC_ERROR__APPOINTMENT_CPACITY_REACH:
        'This class is fully booked. Please try booking different time.',
      RPC_ERROR__APPOINTMENT_RESOURCE_BUSY:
        'Selected worker is taken. Consider booking different time, or another worker.',
      RPC_ERROR__CLIENT_IN_BLACKLIST: 'To make an appointment, please contact administrator',
      RPC_ERROR__APPOINTMENT_MIS_CONFIRM_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR__APPOINTMENT_MIS_RESERVE_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR_BODY:
        "We couldn't connect to the server. Please make sure that Internet is up and running.",
      RPC_ERROR_TITLE: 'Oops, seems like something has gone wrong!',
      SMS_CONFIRM_FAILED: 'SMS verification code is incorrect. Please try again',
      SMS_SEND_FAILED:
        "We couldn't send SMS. Please make sure that phone number you've entered is correct, then try again",
      SMS_CODE_INVALID: 'SMS verification code is incorrect. Please try again',
      JS_NO_SELECTED_RESOURCE: 'You did not select any worker!',
      JS_CRAC_NOT_ACTIVATED: 'CRAC is not activated',
      AUTH_EMAIL_TAKEN:
        'This user already exists! Please deactivate this user by dialing the Hotline Phone Number.',
      TIME_TO_CONFIRM_HAS_EXPIRED: 'Request timed out. Please try again later.',
    },
  },
  components: {
    confirmation: {
      approve: 'تثبيت',
      withoutPayment: 'Pay in clinic',
      onlinePay: 'Pay now',
      ok: 'موافقة',
      cancel: 'الغاء',
      retry: 'حاول مرة أخري',
      confirmCode: 'تأكيد الكود',
      requestSmsCode: 'اطلب رمز المرور بمواسطه رساله قصيره',
      notReciveCode: 'لم تستلم رمز المرور حتى الآن؟',
      sendCodeAgain: 'اطلب الرمز مرة أخرى',
      attention: 'Attention, You will book to the next services:',
      attentionDescription: '',
      minutesShort: 'min',
    },
    marker: {
      choose: 'اختار',
    },
    search: {
      category: 'تخصص',
      resource: 'طبيب',
      business: 'عياده',
      EmptySearch: 'لم يتم العثور على شيء',
    },
    resource: {
      anyAvailable: 'أي طبيب متوفر',
    },
    visitsList: {
      closeBtn: 'Close',
      locationTitle: 'Location',
      addressTitle: 'Address',
      routBtn: 'Find a rout',
      rescheduleBtn: 'Reschedule',
      cancelBtn: 'Cancel',
      noVisits: 'You have no scheduled visits',
      noVisitsText: 'To enroll, start by choosing a referral, branch or doctor',
      noVisitResult: 'No visit result',
    },
    misAuth: {
      form: {
        title: 'Fill your data',
        error: 'No match found. Check your data/',
        name: 'Fill your name and surname',
        insuranceNumber: 'Insuarence number',
      },
      approve: 'Confirm',
    },
    misSelect: {
      title: 'Select insurance type',
      skip: 'Book without insurance',
    },
  },
  navigation: {
    profile: {
      history: 'زيارات',
      list: 'زيارات',
      title: 'الملف الشخصي',
      logOut: 'الخروج',
    },
    home: {},
    appointments: {
      confirm: 'تأكيد',
      category: 'اختار تخصص',
      location: 'موقع',
      resource: 'اختر طبيب',
      service: 'اختر الخدمه',
      subcategory: 'اختر الخدمه',
      time: 'حدد التاريخ والوقت',
      cancel: 'الغاء',
      noTime:
        'Sorry, within next few weeks there are no available timeslots for this doctor. ' +
        'You can go back and select another speciality, doctor or location.',
    },
    chat: {
      title: 'دردشة',
    },
    onHome: {
      title: 'اتصال',
    },
    login: {
      title: 'تسجيل دخول',
    },
    pin: {
      title: 'إنشاء كلمة المرور',
    },
    logout: {
      title: 'خروج',
    },
    maps: {
      title: 'عرض الخريطة',
    },
    recipes: {
      title: 'وصفات',
    },
    powered: {
      gbooking: 'Powered by GBooking',
      medMe: 'Powered by Med.me',
    },
    sms: {
      title: 'SMS',
    },
    password: {
      title: 'Password',
    },
    agreement: {
      title: 'Agreement',
    },
    research: {
      title: 'Research',
    },
    visits: {
      title: 'Visits',
    },
    analysis: {
      title: 'Analysis',
    },
  },
  tabbar: {
    appointments: 'Appointments',
    profile: 'Profile',
    chat: 'Chat',
    onHome: 'House call',
    MedCard: 'Med. card',
    research: 'Research',
    visits: 'Visits',
    analysis: 'Analysis',
  },
  screens: {
    lock: {
      pinError: 'You have entered incorrect PIN',
    },
    ehr: {
      inputSurnamePlaceholder: 'Ваша фамилия',
      inputPatronymicPlaceholder: 'Ваше отчество',
      inputNamePlaceholder: 'Ваше имя',
      birthdayPlaceholder: 'Ваша дата рождения',
      loginInfoText: `Укажите свои фамилию и отчество,
       чтобы получить доступ
        к Медицинской карте`,
      ok: 'Ок!',
      hello: `Добрый день, \${name}!`,
      ehrErrorTitle: `\${name}, похоже,
       у вас ещё нет
        медицинской карты`,
      notYou: 'Это не вы?',
      loginWithAnotherNumber: 'Зайти под другим номером',
      callReception: `или позвоните в регистратуру по
      телефону \${phone}`,
      checkYourData: 'Или проверьте ваши данные',
      bookFirstAppointment: 'Запишитесь на первый приём',
      loginNumber: 'Вы зашли под номером',
      loginError: 'Заполните все поля',
      introduceYourself: 'Представьтесь, пожалуйста:',
    },
    appointments: {
      home: {
        btnsSelect: 'Select',
        visits: 'My visits',
        newFrom: {
          location: 'اختار عياده',
          profession: 'اختار تخصص',
          resource: 'اختار طبيب',
        },
        startHint: 'بدء إنشاء موعد زياره:',
        errorSheetTitle: 'تم اكتشاف عطل اتصال الشبكة!',
        errorSheetMessage:
          'Please make sure your internet connection is up and running, then try again.',
        reloadData: 'تحديث البيانات',
        cancel: 'الغاء',
        recentVisites: 'My last visits',
        startRecordingMessage: 'What would you like to start you booking from?',
        mainButton: {
          profession: {
            title: 'تخصصات',
          },
          location: {
            title: 'عيادات',
          },
          resource: {
            title: 'اطباء',
          },
        },
      },
      form: {
        nameLabel: 'أدخل اسمك الكامل',
        name: 'الاسم الشخصي',
        namePlaceholder: 'ادخل الاسم الشخصي',
        surname: 'اسم العائله',
        surnamePlaceholder: 'ادخل اسم العائله',
        patronymic: 'اسم الاب',
        patronymicPlaceholder: 'ادخل اسم الاب',
        phone: 'هاتف',
        professtion: 'تخصص',
        insuranceNumber: 'رقم التأمين',
        direction: 'تخصص',
        address: 'عنوان',
        date: 'التاريخ',
        dateTime: 'اليوم والساعه',
        total: 'المحموع',
        doctor: 'طبيب',
        discount: 'مع خصم',
        category: 'الفئة',
        subcategory: 'فئة فرعية',
        service: 'خدمه',
        select: 'يرجى التحديد ...',
        time: 'الوقت',
        emailPlaceholder: 'example@email.com',
        emailLabel: 'ادخل بريدك الالكتروني',
        phoneLabel: 'ادخل رقم الهاتف',
        phonePlaceholder: '+1 123 456-78-90',
        addressInfo: {
          notify:
            'This clinic currently does not support online booking. Consider making an appointment by phone',
        },
        SearchInputPlaceholder: 'البحث عن طريق الطبيب أو العياده أو التخصص',
        MedCardSearchInputPlaceholder: 'Doctor, diagnosis, medicine, etc.',
        SingleSearchInputPlaceholder: 'بخث طبيب أو تخصص',
        title: 'استكمال التسجيل',
        description:
          'أدخل التفاصيل الخاصة بك لإكمال تسجيل الزياره. سنرسل رمز التأكيد إلى رقم هاتفك.',
        notice: '— حقول اجباريه',
        nameError: 'Invalid format',
        emailError: 'Invalid email',
        fio: 'الاسم الكامل',
        genderLabel: 'الجنس',
        clientComment: 'ملاحظات حول الزياره',
        birthdayLabel: 'تاريخ ميلادك (dd.mm.yyyy)',
        birthdayLabelShort: 'تاريخ ميلادك',
        genderMale: 'ذ',
        genderFemale: 'ا',
        genderSelect: 'Select gender',
      },
      category: {
        search: 'بحث تخصص',
      },
      splitInsuranceClient: {
        insuranceClient: 'I am an insurance patient',
        commercialClient: 'I will cover the appointment by myself',
        return: 'Return',
        message: 'To make an appointment, please call',
      },
      subcategory: {
        title: 'اختر تخصص',
        search: 'بحث حسب تخصص فرعي',
        skip: 'تخطي اختيار الخدمه',
      },
      service: {
        title: 'اختر تخصص',
        search: 'بحث تخصص',
        from: 'من',
        to: 'الى',
        beginWith: 'من',
      },
      resources: {
        search: 'البحث عن طريق اسم الطبيب والتخصص',
        anyBtn: 'أي طبيب',
        anyOverlay: 'أي طبيب',
        all: 'جميع الأطباء',
        favorites: 'الأطباء المفضلين',
        available: 'متاح من ',
        onlyCall: 'سجل عن طريق الهاتف',
        experience: 'Experience',
        notAvailable: 'غير متوفر لإنشاء موعد زياره',
        clinic: 'عياده',
        availableAddress: 'الطبيب يداوم في هذه العياده:',
        about: 'About',
        nextAvailable: 'Next available date ',
        book: 'Book',
        desriptionTitle: 'DOCTOR INFO',
        closeBtn: 'Close',
      },
      address: {
        search: 'البحث عن طريق العنوان',
        listOption: 'Nearby',
        mapOption: 'Map view',
        anyOverlay: 'Any clinic',
        offline: 'Online booking is not available',
        measure: 'km',
        list: 'List view',
        map: 'Map view',
        book: 'Make an appointment',
        title: 'Select clinic',
        disable: 'Online booking is not available',
      },
      time: {
        businessError:
          'Sorry, but online booking is not configured yet for the selected clinic. ' +
          'You can go back and select different clinic.',
        error:
          'Sorry, but there are no available doctors for the selected speciality within upcoming few weeks. ' +
          'You can go back and select different speciality or location.',
        networkErrorTitle: 'An error occurred while loading data.',
        networkErrorMessage: 'Please make sure that internet is up and running, then try again.',
        ok: 'OK',
        showAvailableSlots: 'عرض فترات زمنية مشغولة',
        errorResourceSlots:
          'Sorry, but there are no available timeslots for the selected doctor, we will ' +
          'show you available timeslots of another worker, instead',
        showDescription: 'معلومات اضافيه عن الطبيب',
        hideDescription: 'اخفاء وصف الطبيب',
      },
      confirm: {
        total: 'المجموع',
        smsCodeHint: 'يرجى إكمال هذا النموذج للتحقق من رقم هاتفك',
        smsCodePlaceholder: 'أدخل الرمز المستلم عبر الرسائل القصيرة',
        phonePlaceholder: 'ادخل رقم جوالك',
        birthdatePlaceholder: 'dd.mm.yyyy',
        done: 'انهاء',
        doneDescription: 'تم انشاء موعد للزياره بنجاح',
        codeErrorTitle: 'You have entered incorrect code',
        phoneErrorTitle: 'You have entered incorrect phone number',
        codeErrorDescription: 'Please enter correct code',
        phoneErrorDescription: 'Correct format: +11231234567',
        thankYou: 'Your appointment has been successfully created! Stay healthy!',
        newRecord: 'Create another appointment',
        agreeText: 'أوافق على تلقي رسائل قصيره تتعلف بالزياره',
        agreeLink: 'قراءة المزيد عن ذلك',
      },
      disable: {
        message: 'Online appointment creation is currently not available',
      },
    },
    profile: {
      history: {
        reRegister: 'Make another appointment',
        edit: 'Edit',
        cancel: 'Cancel',
        inactive: 'This appointment is inactive',
      },
      main: {
        twoFAUserDescription: 'PatientID (EHR)',
        appointmentBlockTitle: 'Appointment to the doctor',
        map: 'Route',
        connectedProfiles: 'LINKED PROFILES',
        authorizationWarning:
          'You need to create an appointment in order to get authenticated in the system.',
        select: 'Select',
        nearVisits: 'UPCOMING VISITS',
        historyOfVisits: 'History of visits',
        recipes: 'Prescriptions',
        createNew: 'Create new appointment',
        createNewInfo: "You don't have any appointments yet",
      },
      splash: `User profile is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    common: {
      splash: {
        btnTitle: 'Create Feedback letter',
        emailTitle: 'Personal Cabinet of the Patient. Feedback form',
        emailBody: `Dear development team!
        My message:

        My name is:
        Please use the following phone number to get in touch with me: \${phone}

        Thank you`,
      },
    },
    recipes: {
      splash: `Module «Prescriptions» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    main: {
      splash: `Module «News and Special Offers» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    chat: {
      splash: `Module «Chat» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    onHome: {
      splash: `Module «House Call» is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    login: {
      loginPlaceholder: 'Login',
      codePlaceholder: 'Code',
      enterBtnTitle: 'Enter',
      info: 'By registering, you accept the terms and conditions of the license agreement',
    },
    logout: {
      actiontitle: 'Profile deletion',
      actionmessage:
        'After completing this action, all your data will be deleted, and you will need to activate the app once again',
      closeAppMessage: 'Are you sure you want to close this application?',
      logOut: 'Quit',
      cancel: 'Return',
      closeApp: 'Exit app',
    },
    code: {
      error: 'The code you have entered is incorrect! Please try again.',
      resend: 'Send the code once again',
      delay: "You'll be able to request your new code once again in:",
      enterCode: 'Please enter below the code that has been sent to your phone number:',
    },
    maps: {
      splash: `Map view for clinics is currently under development
It will be released soon, but you are welcome
to provide us with your feedback`,
    },
    pin: {
      enterCode: 'Enter code',
      recoverCode: 'Forgot your code?',
      touchIdTitle: 'Confirm action',
      info: 'Create your permanent password OR enable TouchID',
    },
    eula: {
      info: 'To continue, please accept our license agreement',
    },
  },
};
