/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { breakpointLarge, breakpointSmall, cardBase, size, unit } from '../styles';
import { navigate, Link, RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SET_PATIENT_ID } from 'data-layer/mutations';


import ModalDialog from 'components/ModalDialog';
import { paths } from 'utils/routing';
import { urlManager } from 'utils/urlManager';
import { useTranslation } from 'react-i18next';
import {
  PageContainer, EhrLogin, EhrTabs, EHRAppointmentBlock, EHRDiagnosticBlock,
  IFilterItem, ScrollBlock, BottomFilter
} from 'components';
import {
  ClientContext, getMultilineTranslation
} from '../utils';
import { SetPatientId, SetPatientIdVariables } from 'data-layer/mutations/__graphql__/SetPatientId';
import { IEHRCounterType, ITheme } from 'data-layer/types';
import { GET_EHR_APPOINTMENTS } from 'data-layer/queries/getPatientAppointments';
import {
  GetEHRAppointments,
  GetEHRAppointmentsVariables,
  GetEHRAppointments_getPatientAppointments_appointments,
} from 'data-layer/queries/__graphql__/GetEHRAppointments';
import { GET_EHR_DIAGNOSTICS } from 'data-layer/queries/getEHRDiagnostics';
import {
  GetEHRDiagnostics,
  GetEHRDiagnosticsVariables,
  GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
} from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { GET_EHR_APPOINTMENT_RESULTS } from 'data-layer/queries/getPatientAppointmentResults';
import {
  GetEHRAppointmentResults,
  GetEHRAppointmentResultsVariables,
  GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults,
} from 'data-layer/queries/__graphql__/GetEHRAppointmentResults';
import {
  dateEHRFilter,
  resourceEHRFilter,
  serviceEHRFilter,
  dateEHRLabFilter,
  resourceEHRLabFilter,
  serviceEHRLabFilter,
  dateEHRResultFilter,
  resourceEHRResultFilter,
} from 'data-layer/helpers';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';
const moment = extendMoment(Moment);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EhrScreenProps extends RouteComponentProps { }

const getEHRFilterValues = ({
  ehrAppointments,
  ehrAppointmentResults,
  diagnostics,
  selectedResourcesKey,
  selectedServicesKey,
}: {
    ehrAppointments: GetEHRAppointments_getPatientAppointments_appointments[];
    ehrAppointmentResults: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults[];
    diagnostics: GetEHRDiagnostics_getEHRDiagnostics_diagnostics[] | null[];
    selectedResourcesKey: string[];
    selectedServicesKey: string[];
}): {
  resources: IFilterItem[];
  taxonomies: IFilterItem[];
} => {
  const resList = new Map<string, IFilterItem>();
  const serviceList = new Map<string, IFilterItem>();
  if (ehrAppointmentResults.length) {
    ehrAppointmentResults.forEach((app: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults) => {
      if (app.doctor?.id) {
        const res: IFilterItem = {
          name: `${app.doctor?.surname || ''} ${app.doctor?.name || ''}`,
          key: `${app.doctor?.id || ''}`,
          active: selectedResourcesKey.includes(`${app.doctor?.id || ''}`),
        };
        resList.set(res.key, res);
      }

    })
  }
  if (ehrAppointments.length) {
    // eslint-disable-next-line no-unused-expressions
    ehrAppointments.forEach((app: GetEHRAppointments_getPatientAppointments_appointments) => {
      const res: IFilterItem = {
        name: `${app.doctor.surname} ${app.doctor.name}`,
        key: `${app.doctor.id}`,
        active: selectedResourcesKey.includes(`${app.doctor.id}`),
      };
      resList.set(res.key, res);

      (app.services || []).forEach((s) => {
        if (!s) {
          return;
        }
        const service: IFilterItem = {
          name: s.name,
          key: s.id,
          active: selectedServicesKey.includes(`${s.id}`),
        };
        serviceList.set(service.key, service);
      });
    });
  }
  if (diagnostics.length) {
    // eslint-disable-next-line no-unused-expressions
    diagnostics.forEach((app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics | null) => {
      if (!app) {
        return;
      }

      (app.result || []).forEach((res) => {
        if (!res) {
          return;
        }
        const name = res.performerDoctor?.name || '';
        const surname = res.performerDoctor?.surname || '';
        const id = res.performerDoctor?.id || '';

        const resource: IFilterItem = {
          name: `${surname} ${name}`,
          key: `${id}`,
          active: selectedResourcesKey.includes(`${id}`),
        };
        if (resource.key) {
          resList.set(resource.key, resource);
        }
      });

      (app.services || []).forEach((s) => {
        if (!s) {
          return;
        }
        const service: IFilterItem = {
          name: s.name,
          key: s.id,
          active: selectedServicesKey.includes(`${s.id}`),
        };
        serviceList.set(service.key, service);
      });
    });
  }

  return {
    resources: Array.from(resList.values()),
    taxonomies: Array.from(serviceList.values()),
  };
};


const EhrScreen: React.FC<EhrScreenProps> = () => {
  // const LOAD_APP_SIZE = 6;
  const clientContext = useContext(ClientContext);
  const [selectedMonth, setSelectedMonth] = useState<IFilterItem[]>([]);
  const [selectedYear, setSelectedYear] = useState<IFilterItem[]>([]);
  const [offset, setOffset] = useState(0);
  // const [pastAppointmentsLimit, setPastAppointmentLimit] = useState(LOAD_APP_SIZE - 1);
  const [selectedResources, setSelectedResource] = useState<IFilterItem[]>([]);
  const [selectedServices, setSelectedServices] = useState<IFilterItem[]>([]);

  const [bottomNav, setBottomNav] = useState(false);
  const onFilterToggle = () => setBottomNav(!bottomNav);

  const getSelectedFiltersString = (): string =>
    [
      ...selectedYear.map((item) => item.name),
      ...selectedMonth.map((item) => item.name),
      ...selectedResources.map((item) => item.name),
      ...selectedServices.map((item) => item.name),
    ].join(', ');

  const cleanFilters = () => {
    setSelectedMonth([]);
    setSelectedYear([]);
    setSelectedResource([]);
    setSelectedServices([]);
  };

  const [showModalInfo, setModalInfoShown] = useState(false);
  const { t } = useTranslation();
  const { theme, user, token, ehrEndPoint, ehrEntities, ehrCounter, ehrCounterSupport, clientId, patientId, businessId } = clientContext
  const context = {
    user,
    token,
    clientId,
    patientId,
    businessID: businessId,
    ehrEndPoint,
  }


  const setUserTokenCookie = async () => {
    const cooReq = {
      jsonrpc: '2.0',
      cred: { user, token },
      method: 'misc.set_user_token_cookie',
      params: {},
      id: 1,
    };
    await fetch(ehrEndPoint, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cooReq),
    });
  }

  // const patientData = useQuery<GetEHRPatientId>(GET_PATIENT_ID);
  const [setPatientData, setPatientDataStatus] = useMutation<SetPatientId, SetPatientIdVariables>(
    SET_PATIENT_ID,
  );

  const appEHRData = useQuery<GetEHRAppointments, GetEHRAppointmentsVariables>(
    GET_EHR_APPOINTMENTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: ehrCounterSupport ? ehrCounter.appointment : 200,
      },
      fetchPolicy: ehrCounterSupport ? 'cache-and-network' : 'cache-only',
      skip: !patientId || !token
    },
  );
  const appEHRResults = useQuery<GetEHRAppointmentResults, GetEHRAppointmentResultsVariables>(
    GET_EHR_APPOINTMENT_RESULTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: ehrCounterSupport ? ehrCounter.appointmentResult : 200,
      },
      fetchPolicy: ehrCounterSupport ? 'cache-and-network' : 'cache-only',
      skip: !patientId || !token
    },
  );
  const diagnosticEHRData = useQuery<GetEHRDiagnostics, GetEHRDiagnosticsVariables>(
    GET_EHR_DIAGNOSTICS,
    {
      variables: {
        ...context,
        offset: 0,
        size: ehrCounterSupport ? ehrCounter.diagnosticReport : 200
      },
      fetchPolicy: ehrCounterSupport ? 'cache-and-network' : 'cache-only',
      skip: !patientId || !token || (ehrEntities.length > 0 && !ehrEntities.includes('diagnosticReport'))
    },
  );
  const ehrAppointments = appEHRData.data?.getPatientAppointments.appointments || [];
  const ehrAppointmentResults = appEHRResults.data?.getPatientAppointmentResults.appointmentResults || [];
  const ehrDiagnostics = diagnosticEHRData.data?.getEHRDiagnostics.diagnostics || [];

  ehrAppointmentResults.sort((a, b) => {
    return moment(a.start).isBefore(moment(b.start)) ? 1 : -1
  })
  ehrAppointments.sort((a, b) => {
    return moment(a.start).isBefore(moment(b.start)) ? 1 : -1
  })
  ehrDiagnostics.sort((a, b) => {
    return moment(a.issuedDate).isBefore(moment(b.issuedDate)) ? 1 : -1
  })

  const defaultEntity = IEHRCounterType.appointmentResult
  const [ehrAppTypeFilter, setEhrAppTypeFilter] = useState(defaultEntity);

  const changeEhrAppTypeFilter = (a: IEHRCounterType) => {
    setEhrAppTypeFilter(a);
  };

  useMemo(() => {
    if (patientId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      // patientData.refetch();
      void setUserTokenCookie().catch(() => { })
    }
  }, [patientId])

  const pageTitle = t('screens.ehr.title');

  const filterRanges = useMemo((): DateRange[] => {
    const ranges: DateRange[] = [];
    if (selectedYear.length) {
      if (selectedMonth.length) {
        selectedMonth
          .map((m) => +m.key)
          .forEach((months) => {
            selectedYear
              .map((y) => +y.key)
              .forEach((year) => {
                const startDate = moment([year, months - 1]);
                const endDate = moment(startDate).endOf('month');
                ranges.push(moment.range(startDate, endDate));
              });
          });
      } else {
        selectedYear
          .map((y) => +y.key)
          .forEach((year) => {
            const startDate = moment([year, 0]);
            const endDate = moment(startDate).endOf('year');
            ranges.push(moment.range(startDate, endDate));
          });
      }
    }
    return ranges;
  }, [selectedYear, selectedMonth]);

  const filteredEHRApps = useMemo(
    () =>
      ehrAppointments
        .filter((a) => dateEHRFilter(a, filterRanges))
        .filter((a) => resourceEHRFilter(a, selectedResources))
        .filter((a) => serviceEHRFilter(a, selectedServices)),
    [ehrAppointments, filterRanges, selectedResources, selectedServices],
  );

  const filteredEHRResultsApps = useMemo(
    () =>
      ehrAppointmentResults
        .filter((a) => dateEHRResultFilter(a, filterRanges))
        .filter((a) => resourceEHRResultFilter(a, selectedResources)),
    [ehrAppointmentResults, filterRanges, selectedResources],
  );

  const filteredEHRDiagnosticApps = useMemo(
    () =>
      ehrDiagnostics
        .filter((a) => dateEHRLabFilter(a, filterRanges))
        .filter((a) => resourceEHRLabFilter(a, selectedResources)),
    [ehrDiagnostics, filterRanges, selectedResources],
  );

  function renderModalInfo () {
    const queryString = urlManager.getQueryString();
    const onCancel = () => navigate(`${paths.homePath}?${queryString}`);
    return <ModalDialog
      isOpen={!showModalInfo}
      title={t('modals.ehrInfo.title')}
      contentLabel={t('modals.ehrInfo.title')}
      confirmButtonTitle={t('modals.ehrInfo.confirmBtn')}
      cancelButtonTitle={t('modals.ehrInfo.cancelBtn')}
      onConfirm={() => { setModalInfoShown(true); }}
      onCancel={onCancel}
      onRequestClose={onCancel}
    >
      {getMultilineTranslation(t('modals.ehrInfo.content'))}
      <div>
        <span>{t('modals.ehrInfo.neverBeen')}</span>
        <StyledLink theme={theme} to={`${paths.newRecordPath}?${queryString}`}>
          {t('modals.ehrInfo.doAppointment')}
        </StyledLink>
        <span>{t('modals.ehrInfo.ehrAccess')}</span>
      </div>
    </ModalDialog>;
  }

  function EHRAppointmentsCards () {
    const pastApps = (filteredEHRApps || []);
    return {
      hasMore: appEHRData.loading,
      cards: pastApps.map((app) => !!app && <EHRAppointmentBlock
        key={'ehrc' + app.id}
        appointmentData={{
          start: app.start,
          doctor: {
            name: app.doctor?.name || '',
            surname: app.doctor?.surname || '',
            specialization: app.doctor?.specialization.name || ''
          },
          services: app.services || [],
          attachments: []
        }}
        path={Number(app.resultId) ? paths.ehrAppointmentPath(app.resultId) : undefined}
      />)
    };
  }

  function EHRAppointmentResultsCards () {
    const appointmentMap = new Map<string, GetEHRAppointments_getPatientAppointments_appointments>()
    ehrAppointments.forEach((app) => {
      appointmentMap.set(app.id, app)
    })
    const getResultSevices = (app: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults): {
      name: string;
    }[] => {
      const { scheduledProcedures, appointmentId } = app
      if (scheduledProcedures.length) {
        return scheduledProcedures.map((proc) => proc.services).flat()
      }

      if (appointmentId && appointmentMap.has(appointmentId)) {
        const appointment = appointmentMap.get(appointmentId)
        return appointment?.services || []
      }

      return []
    }

    const pastApps = (filteredEHRResultsApps || []);

    return {
      hasMore: appEHRResults.loading,
      cards: pastApps.map((app) => !!app && <EHRAppointmentBlock
        key={'ehrr' + app.id}
        appointmentData={{
          start: app.start,
          doctor: {
            name: app.doctor?.name || '',
            surname: app.doctor?.surname || '',
            specialization: app.doctor?.specialization.name || ''
          },
          services: getResultSevices(app),
          attachments: app.attachments.length ? app.attachments : [],
          business: {
            name: app.business.name
          } 
        }}
        path={paths.ehrAppointmentPath(app.id)}
      />)
    }
  }

  function EHRDiagnosticsCards () {
    const pastApps = (filteredEHRDiagnosticApps || []);
    return {
      hasMore: diagnosticEHRData.loading,
      cards: pastApps.map((app) => !!app && <EHRDiagnosticBlock
        key={'ehrd' + app.id}
        appointmentData={app}
      />),
    };
  }

  function FiltersBlock () {
    const { resources, taxonomies } = getEHRFilterValues({
      ehrAppointments: [],
      ehrAppointmentResults,
      diagnostics: [],
      selectedResourcesKey: selectedResources.map((r: IFilterItem) => r.key),
      selectedServicesKey: selectedServices.map((r: IFilterItem) => r.key),
    });
    return <BottomFilter params={{
      selectedMonth,
      selectedYear,
      selectedMonthChange: setSelectedMonth,
      selectedYearChange: setSelectedYear,
      selectedResources,
      selectedResourcesChange: setSelectedResource,
      availableResources: resources,
      availableServices: [],
      selectedServices,
      selectedServicesChange: setSelectedServices,
      cleanFilters,
    }} />;
  }

  function render () {
    const { hasMore, cards }: { hasMore: boolean, cards: React.ReactNode[] } = (
      ehrAppTypeFilter === IEHRCounterType.appointment ? EHRAppointmentsCards() :
        ehrAppTypeFilter === IEHRCounterType.appointmentResult ? EHRAppointmentResultsCards() :
          ehrAppTypeFilter === IEHRCounterType.diagnosticReport ? EHRDiagnosticsCards() :
            { hasMore: false, cards: [] }
    );

    return <div>
      <EhrTabs
        ehrAppTypeFilter={ehrAppTypeFilter}
        setEhrAppTypeFilter={(v: IEHRCounterType) => changeEhrAppTypeFilter(v)}
      />
      <FiltersBlock />
      <AppointmentsWrapper theme={theme}>
        {cards}
      </AppointmentsWrapper>
    </div>;
  }

  return <PageContainerStyled
    title={pageTitle}
    topNav
    contentCSS={{ marginTop: window.innerWidth < breakpointLarge ? '-24px' : '-12px' }}
  >
    <ScrollBlock>
      {!patientId && !showModalInfo && renderModalInfo()}
      {!patientId && showModalInfo && <EhrLogin setPatientData={setPatientData} />}
      {patientId && render()}
    </ScrollBlock>
  </PageContainerStyled>;
};
export default EhrScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const AppointmentsWrapper = styled('div')((props: { theme: ITheme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `auto 0 auto -${size.cardOffset}px`,
  paddingTop: unit,
  '& > *': {
    ...cardBase,
  },
  '& > button': {
    color: props.theme.mainColor,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginTop: unit * 2,
  },
}));

const PageContainerStyled = styled(PageContainer)({
  '.ScrollbarsCustom': {
    width: `calc(100% + ${unit * 6}px) !important`,
    marginLeft: `-${unit * 3}px`,
    marginRight: `-${unit * 3}px`,
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      marginLeft: `-${unit * 1.5}px`,
      marginRight: `-${unit * 1.5}px`,
    },
  },
  '.ScrollbarsCustom-Content': {
    paddingLeft: `${unit * 3}px !important`,
    paddingRight: `${unit * 3}px !important`,
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      paddingLeft: `${unit * 1.5}px !important`,
      paddingRight: `${unit * 1.5}px !important`,
    },
  },
});

const StyledLink = styled(Link)((props: { theme: ITheme }) => ({
  color: props.theme.mainColor,
  fontWeight: 'bold',
  textDecoration: 'none',
}));
