import { IRequest } from '../types';

export function createRequest<T>(
  methodName: string,
  params?: T,
  cred?: { user?: string; token?: string },
): IRequest<T> {
  return {
    jsonrpc: '2.0',
    id: 0,
    cred: cred || {},
    method: methodName,
    ...(params ? { params } : {}),
  };
}
