/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as IconFile } from '../assets/icon-file.svg';
import { ReactComponent as IconLab } from '../assets/icon-lab.svg';
import { ReactComponent as IconDiagnostics } from '../assets/icon-diagnostics.svg';

import { paths } from 'utils/routing';
import { useTranslation } from 'react-i18next';
import { ClientContext } from '../utils';
import { IEHRCounterType } from 'data-layer/types';

interface Props {
  ehrAppTypeFilter: IEHRCounterType,
  setEhrAppTypeFilter: (v: IEHRCounterType) => any
}

export default function({ ehrAppTypeFilter, setEhrAppTypeFilter }: Props) {
  const clientContext = useContext(ClientContext);
  const { t } = useTranslation();
  
  const getTabList = () => {
    const tabsList: ITabsItem[] = [];
    let title
    (clientContext.ehrEntities?.length > 0
      ? clientContext.ehrEntities
      : ['appointment', 'appointmentResult', 'prescription', 'diagnosticReport']
    ).forEach(entity => {
      switch (entity) {
        case 'appointment':
          title = t('screens.ehr.appointment')
          tabsList.push({
            key: IEHRCounterType.appointment,
            name: `${title} (${clientContext.ehrCounter.appointment || 0})`,
            icon: <IconFile height={16} />
          })
          break
        case 'appointmentResult':
          title = t('screens.ehr.appointmentResult')
          tabsList.push({
            key: IEHRCounterType.appointmentResult,
            name: `${title} (${clientContext.ehrCounter.appointmentResult || 0})`,
            icon: <IconFile height={16} />
          })
          break
        case 'diagnosticReport':
          title = t('screens.ehr.diagnosticReport')
          tabsList.push({
            key: IEHRCounterType.diagnosticReport,
            name: `${title} (${clientContext.ehrCounter.diagnosticReport || 0})`,
            icon: <IconLab height={16} />
          })
          break
      }
    })

    return tabsList
  }
  
  return <Tabs
    list={getTabList()}
    value={ehrAppTypeFilter}
    onChange={v => setEhrAppTypeFilter(v as IEHRCounterType)}
  />
}

interface ITabsItem { key: string | number, name: string, icon: React.ReactNode };
const TabsContainer = styled('div')({
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '12px',
  gap: '24px',
  height: '60px'
});
const TabsItem = styled('div')(({ selected }: { selected: boolean }) => ({
  borderBottom: `2px solid ${selected ? '#47C4E4' : 'transparent'}`,
  color: selected ? '#47C4E4' : '#262626',
  fontWeight: selected ? 600 : 400,
  fontSize: '14px',
  lineHeight: '22px',
  display: 'flex',
  cursor: 'pointer',
  userSelect: 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 0px',
  flexShrink: 0,
  '> *': {
    flexShrink: 0
  },
  '> div': {
    marginLeft: '8px'
  }
}));
const Tabs = ({ list, value, onChange }: {
  list: ITabsItem[],
  value: string | number,
  onChange: (value: string | number) => any
}) => <TabsContainer>
    {list.map(item => <TabsItem
      selected={(item.key == value)}
      key={item.key}
      onClick={() => onChange(item.key)}
    >
      {item.icon}
      <div> {item.name} </div>
    </TabsItem>)}
  </TabsContainer>
