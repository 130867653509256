import { gql } from 'apollo-boost';

export const GLOBAL_SEARCH = gql`
  query GlobalSearch($search: String!, $businessID: String, $networkID: String, $type: String) {
    searchInBusiness(search: $search, businessID: $businessID, networkID: $networkID, type: $type)
      @client {
      resources {
        name
        surname
        middleName
        profession
        id
        extraId
        siteId
      }
      taxonomies {
        name
        id
        extraId
        siteId
      }
      locations {
        internalID
        general_info {
          address {
            address
            country
          }
          name
        }
      }
    }
  }
`;
