export interface IEHRCounter {
  // apppointments: number; // записи
  // doctor: number; // результаты приема
  // lab: number; // лаб исследования
  // cardio: number; // ? назначения
  // doc: number;
  appointment: number;
  appointmentResult: number;
  prescription: number;
  diagnosticReport: number;

}

export enum IEHRCounterType {
  appointment,
  appointmentResult,
  prescription,
  diagnosticReport
}
