import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { IFilterItem } from './BottomFilter';
import { breakpointLarge, fullSize, listUnstyled, smallText, unit } from '../styles';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';
import { transparentize } from 'polished';

interface BottomFilterDropdownProps {
  items: IFilterItem[];
  selectedItems: IFilterItem[];
  onSelectFilter: (values: IFilterItem[]) => void;
}

export const BottomFilterDropdown: React.FC<BottomFilterDropdownProps> = ({
  items,
  selectedItems,
  onSelectFilter,
}: BottomFilterDropdownProps) => {
  const { theme } = useContext(ClientContext);
  return (
    <>
      <BackDrop theme={theme} />
      <Container theme={theme}>
        {items.map(({ key, name, active }: IFilterItem) => {
          const onClick = () => {
            let newSelectedItems = [...selectedItems];
            if (active) {
              newSelectedItems = newSelectedItems.filter((item) => item.key !== key);
            } else {
              newSelectedItems.push({ key, name, active: true });
            }

            return onSelectFilter(newSelectedItems);
          };
          return active ? null : (
            <DropdownItem key={key} onClick={onClick}>
              {name}
            </DropdownItem>
          );
        })}
      </Container>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const BackDrop = styled('div')(fullSize, (props: { theme: ITheme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    position: 'absolute',
    zIndex: 5,
    background: transparentize(0.6, props.theme.backdropColor),
  },
}));

const Container = styled('ul')(listUnstyled, (props: { theme: ITheme }) => ({
  position: 'absolute',
  backgroundColor: props.theme.backgroundColor,
  boxShadow: `3px 3px 6px 3px rgb(12, 6, 21, .9)`,
  borderRadius: unit * 1.5,
  fontSize: unit,
  '& > li': {
    margin: unit,
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    top: -unit,
    paddingTop: unit * 2.7,
    minWidth: `calc(100% + ${unit * 2}px)`,
    [`[dir="ltr"] &`]: {
      left: -unit,
    },
    [`[dir="rtl"] &`]: {
      right: -unit,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    zIndex: 5,
    top: '50%',
    left: '50%',
    right: 'auto',
    overflow: 'auto',
  },
}));

const DropdownItem = styled('li')(smallText, {
  margin: unit,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  maxWidth: unit * 30,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontSize: unit * 2,
    lineHeight: 1.15,
  },
});
