import { gql } from 'apollo-boost';


export const GET_EHR_APPOINTMENT = gql`
  query GetEHRAppointment($ID: ID!) {
    getEhrAppointment(id: $ID) @client {
      id
      patientId
      created
      start
      doctor {
        id
        name
        surname
        specialization {
          name
        }
      }
      services {
        id
        name
        price {
          value
        }
        duration
      }
      clientPrice {
        value
      }
      confirmationStatus
      resultId
      source
    }
  }
`;
