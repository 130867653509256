import { gql } from 'apollo-boost';

export const GET_EHR_DIAGNOSTIC_COUNT = gql`
  query GetEHRDiagnosticsCount(
    $businessID: String!
    $clientId: String!
    $patientId: String!
    $user: String!
    $token: String!
    $ehrEndPoint: String!
  ) {
    getEHRDiagnosticsCount(
      businessID: $businessID
      clientId: $clientId
      patientId: $patientId
      user: $user
      token: $token
      ehrEndPoint: $ehrEndPoint
    ) @client {
      count
      support
    }
  }
`;
