import React, { useContext, useEffect, useMemo } from 'react';
import { PageContainer } from '../components';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import {
  GetAppointment,
  GetAppointmentVariables,
  GetAppointment_getAppointment,
} from 'data-layer/queries/__graphql__/GetAppointment';

import {
  GetBusinessInfoVariables,
  GetBusinessInfo,
  GetBusinessInfo_getBusinessInfo,
} from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { useTranslation } from 'react-i18next';
import { GET_BUSINESS_INFO, GET_APPOINTMENT, GET_APPOINTMENTS } from 'data-layer/queries';
import { AppointmentInfoBlock } from 'components/AppointmentInfoBlock';
import {
  GetAppointments,
  GetAppointmentsVariables,
} from 'data-layer/queries/__graphql__/GetAppointments';
import { ClientContext, PostMessageProcessing, urlManager } from 'utils';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { paths } from 'utils/routing';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppointmentsScreenProps extends RouteComponentProps {
  // eslint-disable-next-line react/require-default-props
  appointmentID?: string;
}

const AppointmentsScreen: React.FC<AppointmentsScreenProps> = ({
  appointmentID = '',
}: AppointmentsScreenProps) => {
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const appData = useQuery<GetAppointments, GetAppointmentsVariables>(GET_APPOINTMENTS, {
    variables: {
      clientID: clientContext.clientId,
      user: clientContext.user || '',
      token: clientContext.token || '',
      businessID: urlManager.getBusinessId(),
      networkID: urlManager.getNetworkId(),
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    skip: !clientContext.clientId,
    fetchPolicy: 'cache-and-network',
  });

  const refetchAppointments = appData.refetch;

  const client = clientContext.clientInfo;
  const cred = useMemo<UserCred>(
    () => ({
      user: clientContext.user,
      token: clientContext.token,
      expires: clientContext.expires,
    }),
    [clientContext.user, clientContext.token, clientContext.expires],
  );

  const pmConfig = useMemo(
    () => ({
      client,
      refetchApp: refetchAppointments,
      cred,
      visit: undefined,
    }),
    [client, refetchAppointments, cred],
  );

  useEffect(() => {
    const pm = new PostMessageProcessing(pmConfig);
    pm.subscribe();
    return () => {
      pm.unsubscribe();
    };
  }, [pmConfig]);

  const appointmentData = useQuery<GetAppointment, GetAppointmentVariables>(GET_APPOINTMENT, {
    variables: {
      ID: appointmentID,
    },
  });

  const generalInfoData = useQuery<GetBusinessInfo, GetBusinessInfoVariables>(GET_BUSINESS_INFO, {
    variables: {
      ID: appointmentData.data?.getAppointment?.businessID || '',
    },
    skip: !appointmentData.data?.getAppointment?.businessID,
  });
  const title =
    appointmentData.data?.getAppointment?.taxonomy.alias || t('screens.appointments.noAppFound');

  if (!appointmentData.data?.getAppointment) {
    return <PageContainer title={title}> no data</PageContainer>;
  }
  const selectedAppData: GetAppointment_getAppointment = appointmentData.data?.getAppointment;

  const businessInfo: GetBusinessInfo_getBusinessInfo | undefined =
    generalInfoData?.data?.getBusinessInfo;
  const contentCSS = { padding: 0, overflow: 'hidden' };

  return (
    <PageContainer title={title} contentCSS={contentCSS} topNav backLink={paths.homePath}>
      <AppointmentInfoBlock appointmentData={selectedAppData} businessInfo={businessInfo} />
    </PageContainer>
  );
};

export default AppointmentsScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
