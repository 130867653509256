import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { flexCenter, unit } from '../styles';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

const ModalCloseButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({
  onClick,
}: React.HTMLAttributes<HTMLButtonElement>) => {
  const { theme } = useContext(ClientContext);
  return (
    <ModalCloseButtonStyled theme={theme} onClick={onClick}>
      <IconDelete />
    </ModalCloseButtonStyled>
  );
};

export default ModalCloseButton;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const ModalCloseButtonStyled = styled('button')(flexCenter, (props: { theme: ITheme }) => ({
  position: 'absolute',
  top: unit,
  padding: 0,
  width: unit * 2,
  height: unit * 2,
  border: 'none',
  borderRadius: '50%',
  background: props.theme.mainColor,
  outline: 'none',
  cursor: 'pointer',
  [`[dir="ltr"] &`]: {
    right: unit,
  },
  [`[dir="rtl"] &`]: {
    left: unit,
  },
  '&:hover': {
    opacity: '0.9',
  },
  svg: {
    width: unit,
    height: unit,
    path: {
      stroke: props.theme.backgroundColor,
    },
  },
}));
