import styled from '@emotion/styled';
import { breakpointLarge, size } from '../styles';
import { ITheme } from '../data-layer/types';

const Layout = styled('div')((props: { theme: ITheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  position: 'fixed',
  maxWidth: size.layoutMaxWidthDesktop,
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: props.theme.backgroundColor,
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    maxHeight: size.layoutMaxHeightDesktop,
    borderTopWidth: 4,
    borderTopStyle: 'solid',
    borderTopColor: props.theme.mainColor,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    maxHeight: 'none',
  },
}));

export default Layout;
