import { gql } from 'apollo-boost';

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $name: String!
    $middleName: String!
    $surname: String!
    $birthday: String!
    $gender: Gender!
    $user: String!
    $token: String!
    $medCardId: String
    $passportId: String!
  ) {
    updateUserInfo(
      name: $name
      middleName: $middleName
      surname: $surname
      birthday: $birthday
      gender: $gender
      user: $user
      token: $token
      medCardId: $medCardId
      passportId: $passportId
    ) @client
  }
`;
