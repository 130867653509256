import { Lang } from 'data-layer/types';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { ar_PS, en_us, he_IL, ru_ru } from '../translations';
import { constants } from './config';
import { urlManager } from './urlManager';

export const getLang = (langTag?: string): Lang => {
  const langs = constants.LANGUAGES;
  const queryParamLang = urlManager.getQueryParam('lang');
  const [firstLang] = langs;
  let lang;

  if (queryParamLang) {
    lang = langs.find((l) => l.code === queryParamLang);
  } else if (langTag) {
    lang = langs.find((l) => l.countries.includes(langTag));
  }

  const langToApply = lang || firstLang;

  if (i18n && i18n.language !== langToApply.tag) {
    // should be called with delay to prevent state update while rendering
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(() => i18n.changeLanguage(langToApply.tag));
  }

  return langToApply;
};

export const getContentDirection = (lang: Lang): 'rtl' | 'ltr' => i18n.dir(lang.code);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { trans: en_us },
      ru: { trans: ru_ru },
      ar: { trans: ar_PS },
      il: { trans: he_IL },
    },
    lng: getLang().tag,
    fallbackLng: 'ru',
    debug: true,

    // have a common namespace used around the full app
    ns: ['trans'],
    defaultNS: 'trans',

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
