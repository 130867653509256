export const homePath = '/';
export const profileEditPath = '/profileEdit';
export const profileInfoPath = '/profileInfo';
export const newRecordPath = '/newRecord';
export const searchPath = '/search';
export const ehrPath = '/ehr';
const appointmentsPath = '/appointments';
const ehrAppointmentsPath = '/ehrAppointments';
const ehrAppointmentResultsPath = '/ehrAppointmentResults';
const ehrDiagnosticsPath = '/ehrDiagnostics';
export const appointmentPath = (id: string): string => `${appointmentsPath}/${id}`;
export const ehrAppointmentPath = (id: string): string => `${ehrAppointmentsPath}/${id}`;
export const ehrAppointmentResultPath = (id: string): string => `${ehrAppointmentResultsPath}/${id}`;
export const ehrDiagnosticPath = (id: string): string => `${ehrDiagnosticsPath}/${id}`;
