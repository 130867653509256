import styled from '@emotion/styled';

const Caret = styled('span')({
  fontSize: 7,
  [`[dir="ltr"] &`]: {
    marginLeft: 5,
  },
  [`[dir="rtl"] &`]: {
    marginRight: 5,
  },
  '&:before': {
    content: '"▼"',
  },
});

export default Caret;
