import React, { Component } from 'react';
import styled from '@emotion/styled';
import { withTranslation, WithTranslation } from 'react-i18next';

import { urlManager } from 'utils/urlManager';
import { openWidget, SmallStartRecordItem } from 'components';
import { ITheme, TStartRecordVariant } from '../data-layer/types';

import { ReactComponent as IconClinic } from '../assets/icon-clinic.svg';
import { ReactComponent as IconResource } from '../assets/icon-resource.svg';
import { ReactComponent as IconService } from '../assets/icon-service.svg';
import { breakpointMedium, breakpointSmall, unit } from '../styles';
import { ClientContext } from '../utils/ClientContext';

interface smallStartRecordProps {
  className?: string;
  theme?: ITheme;
}
interface smallStartRecordState {
  password: string;
}

class smallStartRecord extends Component<
  smallStartRecordProps & WithTranslation,
  smallStartRecordState
> {
  openClinicWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'location' });
  };
  
  openServiceWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'category' });
  };

  openResourceWidget = () => {
    openWidget({ networkId: urlManager.getNetworkId(), screen: 'resource' });
  };

  render() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { t, className } = this.props;
    const single = !window.gbookingWidgetSetup.networkId;

    return (
      <Container className={className} theme={theme}>
        {/*variant !== 'big' && */<Title theme={theme}>{t('components.startRecord.title')}</Title>}
        <Inner theme={theme}>
          {single ? <></> :
            <SmallStartRecordItem
              title={t('components.startRecord.clinic')}
              onClick={this.openClinicWidget}
            >
              <IconClinicStyled theme={theme} />
            </SmallStartRecordItem>}
          <SmallStartRecordItem
            title={t('components.startRecord.service')}
            onClick={this.openServiceWidget}
          >
            <IconServiceStyled theme={theme} />
          </SmallStartRecordItem>
          <SmallStartRecordItem
            title={t('components.startRecord.resource')}
            onClick={this.openResourceWidget}
          >
            <IconResourceStyled theme={theme} />
          </SmallStartRecordItem>
        </Inner>
      </Container>
    );
  }
}

smallStartRecord.contextType = ClientContext;

export default withTranslation()(smallStartRecord);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')(({ theme }: smallStartRecordProps) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 1.5 * unit,
  backgroundColor: theme?.startRecordBackgroundColor || 'initial'
}));

const Title = styled('h3')((props: { theme: ITheme }) => ({
  //color: props.theme.mainColor,
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  marginTop: 0,
  marginBottom: `${unit * 2}px`,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    [`[dir="ltr"] &`]: {
      marginLeft: unit,
    },
    [`[dir="rtl"] &`]: {
      marginRight: unit,
    },
  },
}));

const Inner = styled('div')(({ theme }: smallStartRecordProps) => ({
  display: 'flex',
  gap: `${unit * 2}px`,
  flexDirection: 'row',
  flexGrow: 1,
  color: theme?.mainColor,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    flexDirection: 'column',
    marginLeft: unit,
    marginRight: unit,
  },
}));

const IconClinicStyled = styled(IconClinic)((props: { theme: ITheme }) => ({
  path: {
    fill: props.theme.mainColor,
  },
}));

const IconServiceStyled = styled(IconService)((props: { theme: ITheme }) => ({
  path: {
    fill: props.theme.mainColor,
  },
}));

const IconResourceStyled = styled(IconResource)((props: { theme: ITheme }) => ({
  path: {
    fill: props.theme.mainColor,
  },
}));
