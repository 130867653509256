import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { getContentDirection } from '../utils/i18n';
import { ClientContext } from '../utils';

export const ContentDirection: React.FC = () => {
  const { lang } = useContext(ClientContext);

  return (
    <Helmet>
      <body dir={getContentDirection(lang)} />
    </Helmet>
  );
};
