import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { smallText, unit } from '../styles';
import { ReactComponent as IconLocation } from '../assets/icon-location.svg';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

interface LocationProps {
  title: string;
}

const Location: React.FC<LocationProps> = ({ title }: LocationProps) => {
  const { theme } = useContext(ClientContext);
  if (!title) return null;
  const titleRows = title.split(',');
  const locationStr = titleRows.map((s) => <TitleRow key={s}>{s.trim()}</TitleRow>);

  return (
    <Container>
      <IconLocationStyled />
      <Title theme={theme}>{locationStr}</Title>
    </Container>
  );
};

export default Location;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
});

const IconLocationStyled = styled(IconLocation)({
  [`[dir="ltr"] &`]: {
    marginRight: unit,
  },
  [`[dir="rtl"] &`]: {
    marginLeft: unit,
  },
});

const Title = styled('div')(smallText, (props: { theme: ITheme }) => ({
  color: props.theme.mainColor,
}));

const TitleRow = styled('div')({
  '&:not(:last-child):after': {
    content: '","',
  },
});
