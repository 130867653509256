import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useLazyQuery } from '@apollo/react-hooks';

import { PageContainer, GlobalSearch } from '../components';
import { GLOBAL_SEARCH } from 'data-layer/queries';
import * as IGlobalSearch from 'data-layer/queries/__graphql__/GlobalSearch';
import styled from '@emotion/styled';
import { breakpointMedium, largeText, showForMobile, unit } from '../styles';
import { ClientContext, PostMessageProcessing } from 'utils';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { paths } from 'utils/routing';

type SearchScreenProps = RouteComponentProps;

const SearchScreen: React.FC<SearchScreenProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);

  const [globalSearchChange, globalSearchRequestStatus] = useLazyQuery<
    IGlobalSearch.GlobalSearch,
    IGlobalSearch.GlobalSearchVariables
  >(GLOBAL_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const client = clientContext.clientInfo;
  const cred = useMemo<UserCred>(
    () => ({
      user: clientContext.user,
      token: clientContext.token,
      expires: clientContext.expires,
    }),
    [clientContext.user, clientContext.token, clientContext.expires],
  );
  const pmConfig = useMemo(
    () => ({
      client,
      refetchApp: undefined,
      cred,
      visit: undefined,
    }),
    [client, cred],
  );

  useEffect(() => {
    const pm = new PostMessageProcessing(pmConfig);
    pm.subscribe();
    return () => {
      pm.unsubscribe();
    };
  }, [pmConfig]);

  useEffect(() => {
    const { current } = inputRef;
    if (current) {
      current.focus();
    }
  }, []);

  return (
    <PageContainer
      contentCSS={{ overflow: 'visible' }}
      title={t('title.search')}
      topNav
      backLink={paths.homePath}
    >
      <MobTitle>{t('title.search')}</MobTitle>
      <GlobalSearch
        inputRef={inputRef}
        items={globalSearchRequestStatus?.data?.searchInBusiness}
        onChange={globalSearchChange}
      />
    </PageContainer>
  );
};
export default SearchScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const MobTitle = styled('h2')(largeText, showForMobile, {
  marginTop: unit * 5,
  marginBottom: unit * 1.3,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    textAlign: 'center',
  },
});
