import { gql } from 'apollo-boost';

export const GET_EHR_APPOINTMENT_RESULTS = gql`
  query GetEHRAppointmentResults(
    $businessID: String!
    $clientId: String!
    $patientId: String!
    $user: String!
    $token: String!
    $offset: Int
    $size: Int
    $ehrEndPoint: String!
  ) {
    getPatientAppointmentResults(
      businessID: $businessID
      clientId: $clientId
      patientId: $patientId
      user: $user
      token: $token
      offset: $offset
      size: $size
      ehrEndPoint: $ehrEndPoint
    ) @client {
      appointmentResults {
        id
        patientId
        created
        start
        business {
          id
          name
          location
          networkId
        }
        doctor {
          id
          name
          surname
          specialization {
            name
          }
        }
        duration
        anamnesis
        medicalExaminationResult
        diagnosis {
          id
          diagnosisText
          cd10 {
            code
            description
          }
          kind
          type
        }
        recommendations {
          id
          created
          title
          services {
            name
          }
          type
          status
          period {
            begin
            end
          }
          strictPeriod {
            begin
            end
          }
          preparations
          requiredPreparations
          appointmentResultId
        }
        appointmentId
        scheduledProcedures {
          id
          created
          title
          services {
            name
          }
          type
          status
          period {
            begin
            end
          }
          strictPeriod {
            begin
            end
          }
          preparations
          requiredPreparations
          appointmentResultId
        }
        prescriptions {
          id
          created
          recorderDoctor {
            name
            surname
          }
          dosageText
          reasonText
          validityPeriod {
            begin
            end
          }
          numberOfRepeats
          title
          medications {
            form
            amount
            expirationDate
          }
        }
        diagnosticReportIds
        attachments {
          url
          file
        }
      }
    }
  }
`;
