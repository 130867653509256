/* eslint-disable quotes */
import moment from 'moment';
import React, { useContext } from 'react';
import styled from '@emotion/styled';

import UserPic from './UserPic';
import ScrollBlock from './ScrollBlock';
import {
  breakpointLarge,
  hideForMobile,
  mediumText,
  smallText,
  unit,
} from '../styles';

import { ClientContext, constants, dateFormatFunc, getMultilineTranslation } from 'utils';
import { EHR } from 'medme-ehr-js-sdk';
import { AppointmentResultModel } from 'medme-ehr-js-sdk/dist/es5/models/AppointmentResultModel';
import { GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults } from 'data-layer/queries/__graphql__/GetEHRAppointmentResults';

interface EHRAppointmentResultInfoBlock {
  appointmentData: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults;
}

export const EHRAppointmentResultInfoBlock: React.FC<EHRAppointmentResultInfoBlock> = ({
  appointmentData,
}: EHRAppointmentResultInfoBlock) => {
  const { doctor, start, business } = appointmentData;
  const { lang } = useContext(ClientContext);
  const formatter = new EHR.Formatters.SimpleTextFormatterV2(
    EHR.Formatters.SimpleTextFormatter.LOCALIZE['ru-ru'],
    dateFormatFunc({ format: 'D MMMM  YYYY' })
  );
  const result = new AppointmentResultModel();
  result.fromJson(appointmentData);
  const results = formatter.appointmentResult(result);

  const startDate = moment
    .utc(start)
    .calendar(null, { sameElse: lang.dateFormat });

  const getBusinessInfo = () => {
    const { name, location } = business;
    return `${name} ${location}`;
  };

  const resourceName = () => {
    if (doctor) {
      const { name, surname } = doctor;
      return `${surname} ${name}`;
    }

    return '';
  };

  const resourceImage = () => {
    const src = `${constants.defaultResourceImage}`;
    return <UserPicStyled size={80} alt={resourceName()} src={src} />;
  };

  return (
    <>
      <ScrollBlock>
        <Content>
          <Date>{startDate}</Date>
          <Location>{getBusinessInfo()}</Location>
          <Resource>
            {resourceImage()}
            {resourceName()}
          </Resource>
          {getMultilineTranslation(results)}
        </Content>
      </ScrollBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Content = styled('div')({
  width: '100%',
  [`[dir="ltr"] &`]: {
    padding: `0 ${unit * 2}px ${unit * 5}px ${unit * 19.2}px`,
  },
  [`[dir="rtl"] &`]: {
    padding: `0 ${unit * 19.2}px ${unit * 5}px ${unit * 2}px`,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    padding: `0 ${unit * 2}px ${unit * 2}px ${unit * 2}px`,
  },
});

const Date = styled('div')(mediumText, {
  marginBottom: unit * 4,
});

const Resource = styled('div')(mediumText, {
  position: 'relative',
  marginBottom: unit * 4,
});

const Location = styled('div')(smallText, {
  fontWeight: 500,
});

const UserPicStyled = styled(UserPic)(hideForMobile, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [`[dir="ltr"] &`]: {
    left: `-${unit * 10}px`,
  },
  [`[dir="rtl"] &`]: {
    right: `-${unit * 10}px`,
  },
});
