import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_BUSINESS_INFO, USER_LOGIN_DATA } from 'data-layer/queries';
import { UPDATE_USER_INFO, SKIP_UPDATE_USER_INFO } from 'data-layer/mutations';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import * as UserLoginData from 'data-layer/queries/__graphql__/UserData';
import { PageContainer, ProfileForm } from '../components';
import { ICred } from 'data-layer/types/ICred';
import { ClientContext, navigation } from 'utils';
import {
  GetBusinessInfo,
  GetBusinessInfoVariables,
} from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { size } from '../styles';
import { paths } from 'utils/routing';

type ProfileEditScreenProps = RouteComponentProps;

const ProfileEditScreen: React.FC<ProfileEditScreenProps> = () => {
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const cred: ICred = {
    user: clientContext.user,
    token: clientContext.token,
  };
  const userLoginData = useQuery<UserLoginData.UserData>(USER_LOGIN_DATA);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [UpdateUserInfo, UpdateUserInfoStatus] = useMutation<
    IUserInfo.UpdateUserInfo,
    IUserInfo.UpdateUserInfoVariables
  >(UPDATE_USER_INFO, {
    onCompleted: () => {
      clientContext.refetchClient();
    },
  });
  const showProfileScreen = userLoginData?.data?.showProfileScreen;
  const [SkipUpdateUserInfo] = useMutation(SKIP_UPDATE_USER_INFO);
  const onSkip = () => {
    return showProfileScreen ? SkipUpdateUserInfo() : navigation.goBack();
  };
  const cancelButtonTitle = showProfileScreen
    ? t('components.profileForm.cancelSkipButton')
    : t('components.profileForm.cancelButton');

  const generalInfoData = useQuery<GetBusinessInfo, GetBusinessInfoVariables>(GET_BUSINESS_INFO, {
    variables: {
      ID: clientContext.businessId,
    },
    skip: !clientContext.businessId,
  });
  const requireAgreement =
    generalInfoData?.data?.getBusinessInfo.widget_configuration.requireAgreement;
  const requireAgreementLink =
    generalInfoData?.data?.getBusinessInfo.widget_configuration.requireAgreementLink;
  const agreementLink =
    showProfileScreen && requireAgreement && requireAgreementLink ? requireAgreementLink : '';
  const contentCSS = {
    paddingBottom: 0,
    marginLeft: !showProfileScreen ? size.sideNavWidth : 0,
  };

  return (
    <PageContainer
      title={t('title.profileEdit')}
      layoutClassName="profile-edit"
      contentCSS={contentCSS}
      backLink={paths.homePath}
    >
      <ProfileForm
        onSave={UpdateUserInfo}
        clientContext={clientContext}
        cred={cred}
        showSkipBtn={showProfileScreen}
        cancelButtonTitle={cancelButtonTitle}
        agreementLink={agreementLink}
        onSkip={onSkip}
      />
    </PageContainer>
  );
};
export default ProfileEditScreen;
