import moment from 'moment';
import React from 'react';
import styled from '@emotion/styled';

import UserPic from './UserPic';
import { breakpointLarge, hideForMobile, mediumText, smallText, unit } from '../styles';

import { constants, dateFormatFunc, getMultilineTranslation } from 'utils';
import { EHR } from 'medme-ehr-js-sdk';
import { GetEHRDiagnostics_getEHRDiagnostics_diagnostics } from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { ScrollBlock } from './index';
import { IDiagnosticReportMessage } from 'medme-ehr-js-sdk/dist/es5/interfaces';

interface EHRDiagnosticInfoBlockProps {
  appointmentData: GetEHRDiagnostics_getEHRDiagnostics_diagnostics;
}

export const EHRDiagnosticInfoBlock: React.FC<EHRDiagnosticInfoBlockProps> = ({
  appointmentData,
}: EHRDiagnosticInfoBlockProps) => {
  const { issuedDate, resultInterpreter, result } = appointmentData;

  const startDate = moment.utc(issuedDate).calendar(null, { sameElse: 'D MMMM  YYYY' });

  const formatter = new EHR.Formatters.SimpleTextFormatterV2(
    EHR.Formatters.SimpleTextFormatterV2.LOCALIZE['ru-ru'],
    dateFormatFunc({ format: 'D MMMM  YYYY' }),
  );
  const diagnosticReport = formatter.diagnosticReport(appointmentData as IDiagnosticReportMessage);
  const getBusinessInfo = () => {
    if (result?.[0]?.performerBusiness) {
      const { name, location } = result?.[0]?.performerBusiness;
      return `${name} ${location || ''}`;
    }

    return '';
  };

  const resourceName = () => {
    if (resultInterpreter?.[0]?.name) {
      const { name, surname } = resultInterpreter[0];
      return `${surname} ${name}`;
    }

    return '';
  };

  const resourceImage = () => {
    const src = `${constants.defaultResourceImage}`;
    return <UserPicStyled size={80} alt={resourceName()} src={src} />;
  };

  return (
    <>
    <ScrollBlock>
      <Content>        
        <Date>{startDate}</Date>
        <Location>{getBusinessInfo()}</Location>
        <Resource>
          {resourceImage()}
          {resourceName()}
        </Resource>
        {getMultilineTranslation(diagnosticReport)}
      </Content>
    </ScrollBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Content = styled('div')({
  width: '100%',
  [`[dir="ltr"] &`]: {
    padding: `0 ${unit * 2}px ${unit * 5}px ${unit * 19.2}px`,
  },
  [`[dir="rtl"] &`]: {
    padding: `0 ${unit * 19.2}px ${unit * 5}px ${unit * 2}px`,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    padding: `0 ${unit * 2}px ${unit * 2}px ${unit * 2}px`,
  },
});

const Date = styled('div')(mediumText, {
  marginBottom: unit * 4,
});

const Resource = styled('div')(mediumText, {
  position: 'relative',
  marginBottom: unit * 4,
});

const Location = styled('div')(smallText, {
  fontWeight: 500,
});

const UserPicStyled = styled(UserPic)(hideForMobile, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [`[dir="ltr"] &`]: {
    left: `-${unit * 10}px`,
  },
  [`[dir="rtl"] &`]: {
    right: `-${unit * 10}px`,
  },
});
