import moment from 'moment';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { IVisit } from 'data-layer/types/IVisit';
import { PatientProperties } from 'data-layer/helpers';
import { SexEHR, Sex, IContractAttachments } from 'data-layer/types';

export interface IClientPresetData {
  name?: string;
  surname?: string;
  patronymic?: string;
  email?: string;
  phone?: string;
  extraID?: string;
  extraData?: unknown;
  birthday?: string;
  comment?: string;
  gender?: string; // NOT_SPECIFIED | MALE | FEMALE
  attachmentID?: string;
  attachmentNumber?: string;
  attachmentName?: string;
  receptionType?: string;
  id?: string;
  passportId?: string;
}

// This interface describe fields of client data that you can pass into the booking widget.
// This interface is copied from widget repository.
// See `src/interfaces/client/IClientPresetData.ts`.
// Please, if you want to add a new field then add this field in both places here and in the widget.
export interface IWidgetClientPresetData {
  name?: string;
  surname?: string;
  patronymic?: string;
  email?: string;
  phone?: string;
  extraID?: string;
  extraData?: unknown;
  birthday?: string;
  gender?: string; // NOT_SPECIFIED | MALE | FEMALE
  comment?: string;
  insuranceNumber?: string;
  insuranceProgram?: string;
  keepData?: boolean;
  receptionType?: string;
  passportId?: string;
  id?: string;
  extraFields?: { fieldID: string; fieldName?: string; value: any }[];
}

export class PostMessageProcessing {
  private unsubscribeOnClose?: boolean;

  private client?: PatientProperties;

  private visit?: IVisit;

  private cred?: UserCred;

  private widgetSource?: unknown;

  private refetchFn: (() => unknown) | undefined;

  constructor({
    client,
    refetchApp,
    visit,
    cred,
    unsubscribeOnClose,
  }: {
    client?: PatientProperties;
    refetchApp?: () => unknown;
    visit?: IVisit;
    cred?: UserCred;
    unsubscribeOnClose?: boolean;
  }) {
    this.client = client;
    this.visit = visit;
    this.cred = cred;
    this.refetchFn = refetchApp;
    this.unsubscribeOnClose = unsubscribeOnClose;
  }

  private postMessageHandler = (event: MessageEvent) => {
    let messageEvent;
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      messageEvent = JSON.parse(event.data);
    } catch {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { name } = messageEvent;
    let clientData: IWidgetClientPresetData | undefined;
    const clientPhone: string = this.client?.phone || this.visit?.data.client.phone || '';
    if (this.client) {
      const clientEmail = this.client.email;
      const selectedCA: IContractAttachments | undefined = ((contracts: IContractAttachments[]|undefined) => {
        if (!contracts)
          return undefined;

        const now = Date.now();

        // find active contract with latest creation date
        // Because we don't know which timezone is used for dates so we just use UTC
        let ret: IContractAttachments | undefined;
        let lastDate: number = 0;
        let tmp: number = 0;
        for (const ca of contracts)
          if (moment((ca.dateEnd || '01.01.1970') + ' +0000', 'DD.MM.YYYY Z').valueOf() > now && // is it active (last day doesn't contain)?
            (tmp = moment((ca.dateBegin || '01.01.1970') + ' +0000', 'DD.MM.YYYY Z').valueOf()) > lastDate) {
            lastDate = tmp;
            ret = ca;
          }

        return ret;
      })(this.client.contractAttachments);

      clientData = {
        name: this.client.name,
        surname: this.client.surname,
        patronymic: this.client.middleName,
        email: clientEmail,
        phone: clientPhone,
        birthday: moment(this.client.date).format('YYYY-MM-DD') || '',
        insuranceProgram: selectedCA?.attachmentName,
        insuranceNumber: selectedCA?.attachmentNumber,
        keepData: true,
        gender: this.client.gender === SexEHR.MALE ? Sex.MALE : Sex.FEMALE,
        id: this.client.id,
        passportId: this.client.passportId || '',
      };
    }
   //console.log('passing clientData to widget', clientData, this.client)
    switch (name) {
      case 'widget.init':
        if (this.client || this.cred) {
          if (
            (event && event.source && event.source instanceof Window) || // event.source instanceof Window returns false
            (event && event.source && !(event.source instanceof MessagePort))
          ) {
            this.widgetSource = event.source;

            if (this.cred) {
              const credMessage = JSON.stringify({
                name: 'setCred',
                data: {
                  cred: {
                    ...this.cred,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    phone: clientPhone,
                  },
                },
              });
              event.source.postMessage(credMessage, '*');
            }

            if (this.client) {
              const clientMessage = JSON.stringify({
                name: 'setClientData',
                data: {
                  clientData,
                },
              });
              event.source.postMessage(clientMessage, '*');
            }
          }
        }
        break;
      case 'widget.loaded':
        if (this.visit) {
          if (
            (event && event.source && event.source instanceof Window) || // event.source instanceof Window returns false
            (event && event.source && !(event.source instanceof MessagePort))
          ) {
            const appointmentMessage = JSON.stringify({
              name: 'setEditAppointment',
              data: {
                visit: this.visit,
              },
            });
            event.source.postMessage(appointmentMessage, '*');
          }
        }
        break;
      case 'appointment.confirm':
      case 'appointment.update':
        if (this.refetchFn) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.refetchFn();
        }
        break;
      case 'widget.close':
        if (this.unsubscribeOnClose) {
          this.unsubscribe();
        }

        break;
      default:
        break;
    }
  };

  public subscribe = (): void => {
    window.addEventListener('message', this.postMessageHandler, false);
  };

  public unsubscribe = (): void => {
    window.removeEventListener('message', this.postMessageHandler, false);
  };

  public setVisit = (visit?: IVisit): void => {
    this.visit = visit;
  };
}
