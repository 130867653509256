/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  IDiagnosticReportMessage,
  IObservation,
  IObservationComponent,
  IObservationRange,
} from 'medme-ehr-js-sdk/dist/es5/interfaces';
import { EHR } from 'medme-ehr-js-sdk';
import { AppointmentResultModel } from 'medme-ehr-js-sdk/dist/es5/models/AppointmentResultModel';
import { AppointmentConfirmationStatus } from 'medme-ehr-js-sdk/dist/es5/types';
import { AppointmentMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentMessage';
import { AppointmentResultMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentResultMessage';
import { dateFormatFunc } from 'utils';

export const getAppointmentResult = (res: AppointmentResultMessage) => {

  const formatter = new EHR.Formatters.SimpleTextFormatterV2(
    EHR.Formatters.SimpleTextFormatterV2.LOCALIZE['ru-ru'],
    dateFormatFunc({ format: 'D MMMM  YYYY' }),
  );
  const reportInfos = res.reportInfos?.length ? formatter.reportInfos(res.reportInfos, '') : ''
  return {
    __typename: 'PatientAppointmentResult',
    id: res.id,
    patientId: res.patientId,
    created: res.created,
    start: res.start,
    duration: res.duration,
    anamnesis: res.anamnesis || [],
    medicalExaminationResult: res.medicalExaminationResult || [],
    diagnosticReportIds: res.diagnosticReportIds || [],
    business: {
      name: res.business.name || '',
      location: res.business.location || '',
      networkId: res.business.networkId || '',
      id: res.business.id || '',
      __typename: 'EHRBusinessInfo',
    },
    doctor: {
      name: res.doctor.name || '',
      surname: res.doctor.surname || '',
      id: res.doctor.id || '',
      specialization: {
        id: res.doctor.specialization.id || '',
        name: res.doctor.specialization.name || '',
        __typename: 'EHRSpecialization',
      },
      __typename: 'EHRDoctorInfo',
    },
    diagnosis: (res.diagnosis || []).map((d) => {
      return {
        __typename: 'Diagnosis',
        ...d,
        cd10: {
          ...d.cd10,
          __typename: "Cd10"
        }
      };
    }),
    recommendations: (res.recommendations || []).map((r) => {
      return {
        __typename: 'Procedure',
        ...r,
        services: (r.services || []).map((s) => {
          return {
            __typename: 'EHRServices',
            name: s.name,
          };
        }),
        period: {
          ...r.period,
          __typename: 'Period',
        },
        strictPeriod: {
          ...r.strictPeriod,
          __typename: 'Period',
        },
      };
    }),
    appointmentId: res.appointmentId || '',
    scheduledProcedures: (res.scheduledProcedures || []).map((r) => {
      return {
        __typename: 'Procedure',
        ...r,
        services: (r.services || []).map((s) => {
          return {
            __typename: 'EHRServices',
            name: s.name,
          };
        }),
        period: {
          ...r.period,
          __typename: 'Period',
        },
        strictPeriod: {
          ...r.strictPeriod,
          __typename: 'Period',
        },
      };
    }),
    prescriptions: (res.prescriptions || []).map((p) => {
      return {
        ...p,
        recorderDoctor: {
          ...p.recorderDoctor,
          name: p.recorderDoctor.name || '',
          surname: p.recorderDoctor.surname || '',
          __typename: 'EHRDoctorInfo',
        },
        validityPeriod: {
          ...p.validityPeriod,
          __typename: 'Period',
        },
        medications: (p.medications || []).map((m) => {
          return {
            ...m,
            __typename: 'Medication',
          };
        }),
        __typename: 'PrescriptionInfo',
      };
    }),
    reportInfo: reportInfos,
    attachments: (res.attachments || []).map((p) => {
      return {
        ...p,
        __typename: 'AttachmentInfo',
      }
    })
  };
};

export interface fakeEhrApp {
  result: { appointments: AppointmentMessage[] };
}

export interface fakeEhrDiagnostic {
  result: { diagnosticReports: IDiagnosticReportMessage[] };
}

export const parseResponce = (
  appointments: AppointmentMessage[],
  clientVisits: AppointmentMessage[],
) => {
  const formattedAppointments: AppointmentMessage[] = appointments;
  const filteredClientVisits = (formattedAppointments || []).filter((x) => !!x);

  // eslint-disable-next-line no-param-reassign
  clientVisits = clientVisits.concat(clientVisits, filteredClientVisits);

  return clientVisits.map((app) => {
    return {
      ...app,
      id: app.id,
      created: app.created,
      start: app.start,
      source: app.source,
      confirmationStatus:
        app.confirmationStatus || AppointmentConfirmationStatus.ConfirmedByBusiness,
      doctor: {
        ...app.doctor,
        id: app.doctor.id || '',
        name: app.doctor.name || '',
        surname: app.doctor.surname || '',
        specialization: {
          ...(app.doctor.specialization || ''),
          __typename: 'EHRSpecialization',
        },
        __typename: 'EHRDoctorInfo',
      },
      services: app.services.map((s) => {
        return {
          ...s,
          id: s.id || '',
          name: s.name || '',
          duration: s.duration || '',
          price: {
            ...(s.price || { value: 0 }),
            __typename: 'Price',
          },
          __typename: 'AppointmentTaxonomy',
        };
      }),
      clientPrice: {
        ...app.clientPrice,
        __typename: 'Price',
      },
      __typename: 'PatientAppointment',
    };
  });
};

export const parseAppointmentResultResponce = (appointments: AppointmentResultModel[]) => {
  // const getServices =
  return appointments.map((app) => {
    return {
      ...app,
      id: app.id,
      created: app.created,
      start: app.start,
      source: '',
      confirmationStatus: AppointmentConfirmationStatus.ConfirmedByBusiness,
      doctor: {
        ...app.doctor,
        id: app.doctor.id || '',
        name: app.doctor.name || '',
        surname: app.doctor.surname || '',
        specialization: {
          ...(app.doctor.specialization || ''),
          __typename: 'EHRSpecialization',
        },
        __typename: 'EHRDoctorInfo',
      },
      services: [],
      clientPrice: null,
      __typename: 'PatientAppointment',
    };
  });
};

export const parseDiagnostics = (clientDiagnostics: IDiagnosticReportMessage[]) => {
  return clientDiagnostics.map(parseDiagnostic)
}
export const parseDiagnostic = (app: IDiagnosticReportMessage) => {
  return {
    ...app,
    id: app.id,
    business: {
      ...app.business,
      __typename: 'Business',
    },
    patient: {
      ...app.patient,
      date: app.patient.date || new Date(),
      __typename: 'PatientInfo',
    },
    effectivePeriod: app.effectivePeriod
      ? {
        ...(app.effectivePeriod || {}),
        __typename: 'Period',
      }
      : null,
    result: (app.result || []).map((res: IObservation) => {
      return {
        ...res,
        __typename: 'Observation',
        effectivePeriod: {
          ...res.effectivePeriod,
          __typename: 'Preiod',
        },
        performerDoctor: {
          ...res.performerDoctor,
          __typename: 'EHRDoctorInfo',
        },
        performerBusiness: {
          ...res.performerBusiness,
          __typename: 'EHRBusinessInfo',
        },
        value: {
          ...res.value,
          __typename: 'ObservationValue',
        },
        ranges: (res.ranges || []).map((range: IObservationRange) => {
          return {
            ...range,
            age: {
              ...range.age,
              __typename: 'Preiod',
            },
            __typename: 'ObservationRange',
          };
        }),
        components: (res.components || []).map((component: IObservationComponent) => {
          return {
            ...component,
            value: {
              ...component.value,
              __typename: 'ObservationValue',
            },
            ranges: (component.ranges || []).map((range: IObservationRange) => {
              return {
                ...range,
                age: {
                  ...range.age,
                  __typename: 'Preiod',
                },
                __typename: 'ObservationRange',
              };
            }),
            __typename: 'ObservationComponent',
          };
        }),
      };
    }),
    resultInterpreter: (app.resultInterpreter || []).map((r) => {
      return {
        ...r,
        __typename: 'EHRDoctorInfo',
      };
    }),
    services: (app.services || []).map((service) => {
      return {
        ...service,
        __typename: 'EHRServices',
      };
    }),
    attachments: (app.attachments || []).map((p) => {
      return {
        ...p,
        __typename: 'AttachmentInfo',
      }
    }),
    __typename: 'PatientDiagnostic',
  };
};
