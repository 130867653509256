import styled from '@emotion/styled';

import { unit } from '../styles';

const AuthForm = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > *:not(:last-child)': {
    marginBottom: unit * 3,
  },
});

export default AuthForm;
