/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { flexCenter, showForMobile, smallText, unit } from '../styles';
import { ReactComponent as IconFilter } from '../assets/filter.svg';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

interface BlockControlsProps {
  title?: string;
  selected?: string;
  onFilterToggle: () => void;
  onFilterReset: () => void;
}

const BlockControls: React.FC<BlockControlsProps> = ({
  title,
  selected,
  onFilterToggle,
  onFilterReset,
}: BlockControlsProps) => {
  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Wrapper>
        <ButtonStyled theme={theme} onClick={onFilterToggle} style={{ marginLeft: 'auto' }}>
          <Caption>{selected || t('screens.home.filter')}</Caption>
          {selected ? <IconFilterFilled theme={theme} /> : <IconFilterStyled theme={theme} />}
        </ButtonStyled>
        {selected && (
          <ButtonStyled theme={theme} onClick={onFilterReset}>
            <IconDeleteStyled theme={theme} />
          </ButtonStyled>
        )}
      </Wrapper>
    </Container>
  );
};

export default BlockControls;

const Container = styled('div')(showForMobile, {
  width: '100%',
});

const Title = styled('h3')({
  fontWeight: 900,
  fontSize: unit * 2,
  lineHeight: 1.15,
  textAlign: 'center',
});

const Wrapper = styled('div')(flexCenter);

const ButtonStyled = styled('button')(flexCenter, smallText, (props: { theme: ITheme }) => ({
  background: 'none',
  border: 'none',
  outline: 'none',
  padding: 0,
  fontFamily: 'inherit',
  color: props.theme.mainColor,
  margin: `0 ${unit * 0.6}px`,
}));

const Caption = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: `calc(50vw - ${unit * 2.4}px)`,
  margin: `0 ${unit * 0.6}px`,
});

const IconFilterStyled = styled(IconFilter)((props: { theme: ITheme }) => ({
  stroke: props.theme.mainColor,
}));

const IconFilterFilled = styled(IconFilter)((props: { theme: ITheme }) => ({
  fill: props.theme.mainColor,
}));

const IconDeleteStyled = styled(IconDelete)((props: { theme: ITheme }) => ({
  stroke: props.theme.mainColor,
  width: unit * 2,
  height: unit * 2,
}));
