export enum Sex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}
export enum Gender {
  female = 'female',
  male = 'male',
  not_specified = 'n/a',
}

export enum SexEHR {
  MALE = 0,
  FEMALE = 1,
  NOT_SPECIFIED = 2,
}

export enum IAppFilter {
  'all',
  'future',
  'past',
  'telemed',
}

export enum GlobalSearchType {
  resource = 'resource',
  taxonomies = 'taxonomies',
  locations = 'locations',
}
