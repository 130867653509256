import React, { useContext } from 'react';
import { PageContainer, EHRDiagnosticInfoBlock, ScrollBlock, EhrTabs } from '../components';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  GetEHRDiagnosticResult,
  GetEHRDiagnosticResultVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnosticResult';
import { ClientContext } from 'utils';
import { GET_EHR_DIAGNOSTIC_RESULT } from 'data-layer/queries/getEHRDiagnosticResult';

import styled from '@emotion/styled';
import { breakpointSmall, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { navigate } from '@reach/router';
import { paths } from 'utils/routing';
import { IEHRCounterType } from 'data-layer/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EHRDiagnosticsScreenProps extends RouteComponentProps {
  // eslint-disable-next-line react/require-default-props
  appointmentID?: string;
}

const EHRDiagnosticResultScreen: React.FC<EHRDiagnosticsScreenProps> = ({
  appointmentID = '',
}: EHRDiagnosticsScreenProps) => {
  const clientContext = useContext(ClientContext);

  const { t } = useTranslation();
  const appointmentData = useQuery<GetEHRDiagnosticResult, GetEHRDiagnosticResultVariables>(
    GET_EHR_DIAGNOSTIC_RESULT,
    {
      variables: {
        businessID: clientContext.businessId,
        clientId: clientContext.clientId,
        visitId: appointmentID,
        user: clientContext.user,
        token: clientContext.token,
        ehrEndPoint: clientContext.ehrEndPoint,
      },
      skip: !(
        clientContext.token &&
        clientContext.user &&
        appointmentID &&
        clientContext.clientId &&
        clientContext.businessId
      ),
      fetchPolicy: 'network-only',
    },
  );

  const appData = appointmentData.data?.getEHRDiagnosticResult;
  const error = appointmentData.error?.networkError?.message;
  const contentCSS = { alignItems: 'flex-start', padding: '0 30px', overflow: 'hidden' };
  const title = '';
  return (
    <PageContainer title={title} contentCSS={contentCSS} topNav backLink={paths.ehrPath}>
      <EhrTabs
        ehrAppTypeFilter={IEHRCounterType.diagnosticReport}
        setEhrAppTypeFilter={(v: IEHRCounterType) => {
          navigate(paths.ehrPath + '?' + urlManager.getQueryString())
        }}
      />
      <div style={{marginBottom: unit * 3 + 'px'}}></div>
      {appData && <EHRDiagnosticInfoBlock appointmentData={appData} />}
      {error && t('screens.ehr.ehrError')}
    </PageContainer>
  );
};

export default EHRDiagnosticResultScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
