/**
 * manager for URL processing
 */

// import * as _ from 'lodash-es';
export class urlManager {
  public static getQueryParam = (param: string): string => {
    const vars = window.location.search.substring(1).split('&');
    const val = vars.find((pairValue: string) => {
      const pair = pairValue.split('=');

      return pair[0] === param;
    });
    if (val) {
      const pair = val.split('=');

      return pair[1];
    }

    return '';
  };

  public static getQueryString = (): string => {
    return window.location.search.substring(1);
  };

  public static getBusinessId = (): string => {
    return urlManager.getQueryParam('BUSINESS_ID');
  };

  public static getNetworkId = (): string => {
    return urlManager.getQueryParam('NETWORK_ID');
  };

  public static getContractExtraId = (): string => {
    return urlManager.getQueryParam('contractExtraId');
  };

  public static getContractId = (): string => {
    return urlManager.getQueryParam('contractID');
  };
}
