import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Button, UserPic } from './index';
import { breakpointLarge, breakpointMedium, unit } from '../styles';
import { ClientContext } from '../utils';

interface IProfilePicBlockProps {
  className?: string;
  userPicSrc?: string;
  onFileChange: () => void;
}

const ProfilePicBlock: React.FC<IProfilePicBlockProps> = (props: IProfilePicBlockProps) => {
  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  const { className, userPicSrc, onFileChange } = props;

  return (
    <UserPicForm className={className}>
      <UserPic size={unit * 13} src={userPicSrc} />
      <ButtonStyled theme={theme} variant="link" type="button" onClick={onFileChange}>
        {t('components.profileInfo.changePhoto')}
      </ButtonStyled>
    </UserPicForm>
  );
};

export default ProfilePicBlock;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const UserPicForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    top: unit * 4,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    top: unit * 2,
  },
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    position: 'absolute',
    left: unit * 2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    alignItems: 'center',
  },
});

const ButtonStyled = styled(Button)({
  marginTop: unit * 2,
});
