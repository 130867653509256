import React, { useContext } from 'react';
import { Scrollbar, ScrollbarProps } from 'react-scrollbars-custom';

import { getContentDirection } from '../utils/i18n';
import { ClientContext } from '../utils';

const ScrollBlock: React.FC<ScrollbarProps> = ({ children }: ScrollbarProps) => {
  const { lang } = useContext(ClientContext);
  return <Scrollbar dir={getContentDirection(lang)}>{children}</Scrollbar>;
};

export default ScrollBlock;
