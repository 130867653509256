import React, { useMemo, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { NewRecordScreenTable, PageContainer } from 'components';
import { useTranslation } from 'react-i18next';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { PostMessageProcessing } from 'utils/postmessage';
import { ClientContext, urlManager } from 'utils';
import { useQuery } from '@apollo/react-hooks';
import {
  GetAppointments,
  GetAppointments_getAppointmentHistory_appointments,
  GetAppointmentsVariables,
} from '../data-layer/queries/__graphql__/GetAppointments';
import { GET_APPOINTMENTS } from '../data-layer/queries';
import { paths } from 'utils/routing';

type NewRecordScreenProps = RouteComponentProps;

const NewRecordScreen: React.FC<NewRecordScreenProps> = () => {
  const clientContext = useContext(ClientContext);
  const { user, token, expires, ehrCounter } = clientContext;
  const client = clientContext.clientInfo;
  const { t } = useTranslation();
  const appData = useQuery<GetAppointments, GetAppointmentsVariables>(GET_APPOINTMENTS, {
    variables: {
      clientID: clientContext.clientId,
      user: clientContext.user || '',
      token: clientContext.token || '',
      businessID: urlManager.getBusinessId(),
      networkID: urlManager.getNetworkId(),
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    skip: !clientContext.clientId,
    fetchPolicy: 'cache-and-network',
  });
  const appointments: (GetAppointments_getAppointmentHistory_appointments | null)[] =
    appData.data?.getAppointmentHistory.appointments || [];
  const ehrCounterSum = (Object.values(ehrCounter) as number[]).reduce((sum, val) => sum + val, 0);
  const title =
    appointments.length || !!ehrCounterSum ? t('title.newRecord') : t('title.homeEmpty');

  const cred = useMemo<UserCred>(
    () => ({
      user,
      token,
      expires,
    }),
    [user, token, expires],
  );
  const pmConfig = useMemo(
    () => ({
      client,
      refetchApp: undefined,
      cred,
      visit: undefined,
    }),
    [client, cred],
  );

  useEffect(() => {
    const pm = new PostMessageProcessing(pmConfig);
    pm.subscribe();
    return () => {
      pm.unsubscribe();
    };
  }, [pmConfig]);
  return (
    <PageContainer title={title} topNav backLink={paths.homePath}>
      <NewRecordScreenTable />
    </PageContainer>
  );
};
export default NewRecordScreen;
