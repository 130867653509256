import { MedMeAPI, GBookingCoreV2 } from 'corev2-ts-sdk';
import { initMedMeSDK } from 'utils';

initMedMeSDK();

export async function getAppointmentHistory(
  clientId: string,
  cred: GBookingCoreV2.Cred,
  businessID?: string,
  networkID?: string,
  contractID?: string,
  contractExtraId?: string,
): Promise<GBookingCoreV2.Appointment[]> {
  if (MedMeAPI.setCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.setCredentials(cred);
  }
  const params: GBookingCoreV2.AppointmentGetAppointmentsByUserRequestParams = {
    business: {
      id: businessID || '',
    },
    page: 0, // TODO: add cursor and page loading
    pageSize: 150,
    fill_business_data: true,
    extraFilters: {
      sort: [
        {
          dir: 'desc' as GBookingCoreV2.Dir,
          field: 'start' as GBookingCoreV2.SortField,
        },
      ],
    },
  };
  if (contractExtraId) {
    params.contract = { extraId: contractExtraId };
  } else if (contractID) {
    params.contract = { id: contractID };
  }
  if (networkID) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    params.network = {
      id: networkID,
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const appointmentsResult: GBookingCoreV2.AppointmentGetAppointmentsByUserResponseResult = await MedMeAPI.appointment.getAppointmentByUser(
    params,
  );
  if (MedMeAPI.clearCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.clearCredentials();
  }
  return (appointmentsResult && appointmentsResult.data) || [];
}
