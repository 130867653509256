import axios from 'axios';

import { createRequest } from './createRequest';
import { GBookingCoreV2 } from 'corev2-ts-sdk';
import { GlobalSearchType } from 'data-layer/types';

interface IGlobalSearchObject {
  filter: {
    text: string;
    type?: GlobalSearchType;
  };
  business?: { id: string };
  network?: { id: string };
}

export interface ISearchInBusinessParams {
  search: string;
  networkId?: string;
  businessId?: string;
  GBOOKING_COREV2_RPC_URL: string;
  type?: GlobalSearchType;
}

export interface IGlobalSearchLocation {
  internalID: string;
  general_info: GBookingCoreV2.BusinessInfo;
}

export interface IGlobalSearchResult {
  locations: IGlobalSearchLocation[];
  resources: GBookingCoreV2.Resource[];
  taxonomies: GBookingCoreV2.InfoTaxonomy[];
}

export const createGlobalSearchObject = ({
  search,
  networkId,
  businessId,
  type,
}: {
  search: string;
  networkId?: string;
  businessId?: string;
  type?: GlobalSearchType;
}): IGlobalSearchObject => {
  const params: IGlobalSearchObject = {
    filter: {
      text: search,
    },
  };
  if (networkId) {
    params.network = {
      id: networkId,
    };
  } else if (businessId) {
    params.business = {
      id: businessId,
    };
  }
  if (type) {
    params.filter.type = type;
  }

  return params;
};
export async function searchInBusiness({
  search,
  type,
  networkId,
  businessId,
  GBOOKING_COREV2_RPC_URL,
}: ISearchInBusinessParams): Promise<IGlobalSearchResult> {
  return axios
    .post(
      GBOOKING_COREV2_RPC_URL,
      createRequest(
        'business.global_search',
        createGlobalSearchObject({ search, networkId, businessId, type }),
      ),
    )
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        if (response.data && response.data.result) {
          return response.data.result;
        }
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IGlobalSearchResult>;
}
