import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Button from './button';
import ModalBasic, { IModalProps } from './ModalBasic';
import { breakpointLarge, mediumText, unit } from '../styles';
import { ClientContext } from '../utils';

interface IModalDialogProps extends IModalProps {
  isOpen: boolean;
  title?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  changeButtonTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onChange?: () => void;
  onRequestClose?: () => void;
  children?: React.ReactNode;
}

const ModalDialog: React.FC<IModalDialogProps> = (props: IModalDialogProps) => {
  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  const {
    isOpen,
    title,
    confirmButtonTitle = t('components.confirmation.approve'),
    cancelButtonTitle = t('components.confirmation.cancel'),
    changeButtonTitle = '',
    onConfirm,
    onCancel,
    onChange,
    onRequestClose,
    children,
  } = props;

  const renderButtons = () => {
    if (onConfirm || onCancel) {
      return (
        <Buttons>
          {onCancel ? (
            <Button theme={theme} variant="transparent" type="button" onClick={onCancel}>
              {cancelButtonTitle}
            </Button>
          ) : null}
          {onChange && (
            <Button theme={theme} variant="transparent" type="button" onClick={onChange}>
              {changeButtonTitle}
            </Button>
          )}
          {onConfirm ? (
            <Button theme={theme} type="button" onClick={onConfirm}>
              {confirmButtonTitle}
            </Button>
          ) : null}
        </Buttons>
      );
    }
    return null;
  };

  return (
    <ModalBasic variant="dialog" isOpen={isOpen} onRequestClose={onRequestClose}>
      {title ? <Title>{title}</Title> : ''}
      <Text>{children}</Text>
      {renderButtons()}
    </ModalBasic>
  );
};
ModalDialog.defaultProps = {
  title: '',
  confirmButtonTitle: '',
  cancelButtonTitle: '',
  changeButtonTitle: '',
  onConfirm: undefined,
  onCancel: undefined,
  onChange: undefined,
  onRequestClose: undefined,
  children: undefined,
};

export default ModalDialog;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Title = styled('h2')({
  fontWeight: 900,
  fontSize: 26,
  lineHeight: 1.15,
  textAlign: 'center',
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginTop: 'auto',
  },
});

const Text = styled('div')(mediumText, {
  fontWeight: 400,
  textAlign: 'center',
  '& > *:not(:last-child)': {
    marginBottom: unit * 2,
  },
});

const Buttons = styled('div')({
  display: 'flex',
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    flexDirection: 'column-reverse',
    marginTop: 'auto',
    marginBottom: -unit,
    '& > *': {
      marginBottom: unit,
    },
  },
});
