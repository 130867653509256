import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { smallText } from '../styles';
import { IAppointmentStart } from '../data-layer/types';

interface CardTelemedInfoProps {
  appointmentStart: IAppointmentStart;
}

export const CardTelemedInfo: React.FC<CardTelemedInfoProps> = ({
  appointmentStart,
}: CardTelemedInfoProps) => {
  const { t } = useTranslation();
  const { appSoonStart, appStarted, appInPast } = appointmentStart;

  if (!(appSoonStart || appStarted || appInPast)) {
    return <Container>{t('components.appointmentBlock.infoNotification')}</Container>;
  }
  if (appSoonStart || appStarted) {
    return (
      <div>
        <Container>{t('components.appointmentBlock.infoChange')}</Container>
        {appSoonStart && <Container>{t('components.appointmentBlock.infoSoon')}</Container>}
        {appStarted && <Container>{t('components.appointmentBlock.infoStarted')}</Container>}
      </div>
    );
  }

  return <></>;
};
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
const Container = styled('div')(smallText, {
  fontWeight: 500,
});
