import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { flexCenter, fullSize, hideForMobile, unit } from '../styles';

import { ReactComponent as IconDots } from '../assets/icon-dots.svg';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

export interface ICardDropdownItemProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  selected?: boolean;
  onClick: () => void;
}

interface ICardDropdownProps {
  visible: boolean;
  onShow: () => void;
  onHide: () => void;
  items: ICardDropdownItemProps[];
}

interface IIconProps {
  selected?: boolean;
}

const CardDropdown: React.FC<ICardDropdownProps> = ({
  visible,
  onShow,
  onHide,
  items,
}: ICardDropdownProps) => {
  const { theme } = useContext(ClientContext);
  return (
    <Wrapper>
      <TriggerButton onClick={onShow}>
        <IconDotsStyled theme={theme} />
      </TriggerButton>
      {visible && (
        <Dropdown theme={theme}>
          <Backdrop onClick={onHide} />
          {items.map(({ selected, onClick, icon }: ICardDropdownItemProps) => {
            const IconStyled = styled(icon)((props: IIconProps) => {
              if (props.selected) {
                return { path: { stroke: theme.chevronColor } };
              }
              return { path: { fill: theme.mainColor } };
            });
            return (
              <TriggerButton key={onClick.toString()} onClick={onClick}>
                <IconStyled selected={selected} />
              </TriggerButton>
            );
          })}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default CardDropdown;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Wrapper = styled('div')(hideForMobile, {
  position: 'absolute',
  marginTop: '10px', //`-${unit * 0.5}px`,
  [`[dir="ltr"] &`]: {
    right: unit,
  },
  [`[dir="rtl"] &`]: {
    left: unit,
  },
});

const TriggerButton = styled('button')(flexCenter, {
  width: unit * 3,
  height: unit * 3,
  padding: 0,
  border: 'none',
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
});

const Backdrop = styled('div')(fullSize, {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
});

const Dropdown = styled('div')((props: { theme: ITheme }) => ({
  background: props.theme.backgroundColor,
  boxShadow: `${unit * 0.2}px ${unit * 0.2}px ${unit * 2}px rgba(12, 6, 21, 0.12)`,
  borderRadius: unit * 1.5,
  padding: unit * 0.5,
  position: 'absolute',
  top: 0,
  zIndex: 1,
  [`[dir="ltr"] &`]: {
    right: 0,
  },
  [`[dir="rtl"] &`]: {
    left: 0,
  },
}));

const IconDotsStyled = styled(IconDots)((props: { theme: ITheme }) => ({
  path: {
    fill: props.theme.mainColor,
    stroke: props.theme.mainColor,
  },
}));
