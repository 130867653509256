import React, { Component } from 'react';
import styled from '@emotion/styled';

import PageContainer from './pageContainer';
import Heading from './Heading';
import AuthForm from './AuthForm';
import Button from './button';
import { iconType } from './Input';
import CodeInput from './CodeInput';
import { smallText, unit, contentCSS } from '../styles';
import * as RequestSmsCode from '../pages/__graphql__/RequestSmsCode';
import * as ConfirmSmsCode from '../pages/__graphql__/ConfirmSmsCode';
import { withTranslation, WithTranslation } from 'react-i18next';
import { checkPhone } from 'utils/phoneUtils';
import InputAlert from './InputAlert';
import { PhoneInputComponent as PhoneInput } from './PhoneInput';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { ClientContext } from '../utils/ClientContext';

export const SMS_CODE_LENGTH = 5;

interface LoginSmsFormProps {
  requestSmsCode: (a: { variables: RequestSmsCode.RequestSmsCodeVariables }) => void;
  confirmSmsCode: (a: { variables: ConfirmSmsCode.ConfirmSmsCodeVariables }) => void;
  switchAuth: () => void;
  phone: string;
  onPhoneChange: (phone: string, country: string) => void;
  smsToken: string;
  businessInfo: GetBusinessInfo_getBusinessInfo;
  country?: string;
  smsSent?: boolean;
  error?: boolean;
  errorRequestCode?: boolean;
  errorConfirmCode?: boolean;
}
interface LoginSmsFormState {
  phone: string;
  country: string;
  phoneValid: boolean;
  errorVisible: boolean;
}

class LoginSmsForm extends Component<LoginSmsFormProps & WithTranslation, LoginSmsFormState> {
  private codeInput?: React.MutableRefObject<HTMLInputElement[] | null>;

  constructor(props: LoginSmsFormProps & WithTranslation, context?: unknown) {
    super(props, context);
    const { phone, error, country = '' } = this.props;
    const phoneValid = !phone.length || checkPhone(phone, country);
    const errorVisible = !!error;
    this.state = { phone, phoneValid, errorVisible, country: country || '' };
  }

  componentDidMount() {
    this.focusCodeInput();
  }

  componentDidUpdate() {
    this.focusCodeInput();
  }

  focusCodeInput = () => {
    const { smsSent } = this.props;
    const { codeInput } = this;
    if (smsSent && codeInput) {
      const { current } = codeInput;
      if (current && current.length) {
        current[0].focus();
      }
    }
  };

  onChangePhone = (phone: string, phoneValid: boolean, countryCode?: string) => {
    const { country } = this.state;
    const { onPhoneChange } = this.props;
    // if (countryCode !== country) {
      onPhoneChange(phone, countryCode || '');
    // }
    this.setState({ phone, phoneValid, country: countryCode || '', errorVisible: false });
  };

  onSubmit = () => {
    const { phone, country } = this.state;
    const { onPhoneChange, requestSmsCode } = this.props;
    onPhoneChange(phone, country);
    return requestSmsCode({
      variables: {
        phone,
        country,
      },
    });
  };

  onChangeSmsCode = (smsCode: string) => {
    this.setState({ errorVisible: false });
    this.codeInput?.current?.[smsCode.length]?.focus?.();
    this.codeInput?.current?.[smsCode.length]?.scrollIntoView?.();
    if (smsCode.length === SMS_CODE_LENGTH) {
      const { confirmSmsCode, smsToken } = this.props;

      confirmSmsCode({
        variables: {
          token: smsToken,
          code: smsCode,
        },
      });
    }
  };

  requestSmsCode() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { phoneValid } = this.state;
    const { smsSent, t } = this.props;
    return (
      <>
        {this.renderPhoneInput()}
        {phoneValid && !smsSent ? (
          <>
            <Button theme={theme} onClick={this.onSubmit}>
              {t('components.auth.getSmsCode')}
            </Button>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  confirmSmsCode() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { errorVisible } = this.state;
    const { t } = this.props;
    
    return (
      <>
        <StyledLabel>{t('components.auth.smsCode')}</StyledLabel>
        <CodeInputWrapper>
          <CodeInput
            length={SMS_CODE_LENGTH}
            onChange={this.onChangeSmsCode}
            icon={errorVisible ? 'error' : undefined}
            getRef={(codeInput) => {
              this.codeInput = codeInput;
            }}
          />
          {errorVisible && (
            <InputAlert theme={theme}>{t('components.auth.errorSmsCode')}</InputAlert>
          )}
        </CodeInputWrapper>
        <Button theme={theme} onClick={this.onSubmit}>
          {t('components.auth.resendSmsCode')}
        </Button>
      </>
    );
  }

  renderPhoneInput() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { t, businessInfo, country } = this.props;
    const { phone, phoneValid, errorVisible } = this.state;
    let icon;
    if (phone.length) {
      icon = errorVisible || !phoneValid ? 'error' : 'success';
    }
    return (
      <PhoneInput
        phone={phone}
        country={country}
        icon={icon as iconType}
        onPhoneChange={this.onChangePhone}
        businessInfo={businessInfo}
      >
        {!phoneValid && <InputAlert theme={theme}>{t('components.auth.errorPhone')}</InputAlert>}
      </PhoneInput>
    );
  }

  render() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { smsSent, t, switchAuth } = this.props;
    return (
      <PageContainerStyled title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
        <StyledHeading>{t('components.auth.phoneNumber')}</StyledHeading>
        <StyledDiv>
          {this.requestSmsCode()}
          {smsSent ? this.confirmSmsCode() : ''}
          <Button theme={theme} variant="link" onClick={switchAuth}>
            {t('components.auth.rememberPasswordButtonLabel')}
          </Button>
        </StyledDiv>
      </PageContainerStyled>
    );
  }
}

LoginSmsForm.contextType = ClientContext;

export default withTranslation()(LoginSmsForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const PageContainerStyled = styled(PageContainer)({
  overflow: 'auto'
});

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});
const StyledLabel = styled('span')(smallText, {
  fontWeight: 500,
});
const StyledDiv = AuthForm.withComponent('div');
const CodeInputWrapper = styled('div')({
  position: 'relative',
});
