/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Button from './button';
import {
  size,
  unit,
  breakpointLarge,
  largeText,
  showForMobile,
  smallText,
  flexCenter,
} from '../styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BottomFilterItem, BottomFilterDropdown } from '.';
import { ClientContext } from '../utils';
import { ITheme } from '../data-layer/types';

export interface IFilterItem {
  key: string;
  name: string;
  active: boolean;
}
interface IBottomFilterProps {
  params: {
    selectedMonth: IFilterItem[];
    selectedMonthChange: (month: IFilterItem[]) => void;
    selectedYear: IFilterItem[];
    selectedYearChange: (years: IFilterItem[]) => void;
    availableResources: IFilterItem[];
    selectedResources: IFilterItem[];
    selectedResourcesChange: (resources: IFilterItem[]) => void;
    availableServices: IFilterItem[];
    selectedServices: IFilterItem[];
    selectedServicesChange: (services: IFilterItem[]) => void;
    availableLocations?: IFilterItem[];
    selectedLocations?: IFilterItem[];
    selectedLocationsChange?: (locations: IFilterItem[]) => void;
    cleanFilters: () => void;
  };
}

export const BottomFilter: React.FC<IBottomFilterProps> = (
  props: IBottomFilterProps,
): JSX.Element => {
  const { params } = props;
  const {
    selectedMonth,
    selectedMonthChange,
    selectedYear,
    selectedYearChange,
    selectedResources,
    selectedResourcesChange,
    availableResources,
    availableServices,
    selectedServices,
    selectedServicesChange,
    availableLocations,
    selectedLocations,
    selectedLocationsChange,
    cleanFilters,
  } = params;
  const [month, setMonth] = useState(selectedMonth);
  const [year, setYear] = useState(selectedYear);
  const [resources, setResoures] = useState(selectedResources);
  const [services, setServices] = useState(selectedServices);
  const [locations, setLocations] = useState(selectedLocations || null);

  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  const getMonthName = (month: number) => {
    return moment
      .utc()
      .startOf('year')
      .add(month - 1, 'month')
      .format('MMMM');
  };
  const showResetBtn =
    !!selectedMonth.length ||
    !!selectedYear.length ||
    !!selectedServices.length ||
    !!selectedResources.length;

  const showFilter = ({ type }: { type: string }) => {
    let onSelectFilter: (values: IFilterItem[]) => void = () => null;
    let items: IFilterItem[] = [];
    let selectedItems: IFilterItem[] = [];
    let selectedItemsKey: string[] = [];

    switch (type) {
      case 'month':
        selectedItems = selectedMonth;
        selectedItemsKey = selectedItems.map((s) => s.key);
        for (let i = 1; i <= 12; i += 1) {
          const monthName = getMonthName(i);
          items.push({
            key: `${i}`,
            name: monthName,
            active: selectedItemsKey.includes(`${i}`),
          });
        }

        onSelectFilter = selectedMonthChange;
        break;
      case 'years':
        // eslint-disable-next-line no-case-declarations
        selectedItems = selectedYear;
        selectedItemsKey = selectedItems.map((s) => s.key);
        const year = new Date().getFullYear();
        for (let i = year + 1; i >= year - 20; i -= 1) {
          items.push({
            key: `${i}`,
            name: `${i}`,
            active: selectedItemsKey.includes(`${i}`),
          });
        }

        onSelectFilter = selectedYearChange;
        break;
      case 'resources':
        selectedItems = selectedResources;
        selectedItemsKey = selectedResources.map((r) => r.key);
        items = availableResources.map((r) => {
          return { ...r, active: selectedItemsKey.includes(r.key) };
        });
        onSelectFilter = selectedResourcesChange;
        break;
      case 'services':
        items = availableServices;
        selectedItemsKey = selectedResources.map((r) => r.key);
        selectedItems = selectedServices.map((r) => {
          return { ...r, active: selectedItemsKey.includes(r.key) };
        });
        onSelectFilter = selectedServicesChange;
        break;
      case 'locations':
        if (availableLocations && availableLocations.length && selectedLocationsChange) {
          items = availableLocations;
          onSelectFilter = selectedLocationsChange;
        }
        break;
      default:
    }

    return (
      <BottomFilterDropdown
        items={items}
        selectedItems={selectedItems}
        onSelectFilter={onSelectFilter}
      />
    );
  };
  const onBackClick = () => {
    selectedMonthChange(month);
    selectedYearChange(year);
    selectedResourcesChange(resources);
    selectedServicesChange(services);
    if (locations && selectedLocationsChange) {
      selectedLocationsChange(locations);
    }
  };
  const onApplyClick = () => {
    setMonth(selectedMonth);
    setYear(selectedYear);
    setResoures(selectedResources);
    setServices(selectedServices);
    if (locations && selectedLocations) {
      setLocations(selectedLocations);
    }
  };

  return (
    <Wrapper>
      <BottomFilterItem
        title={t('components.bottomFilters.month')}
        selected={selectedMonth
          .sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10))
          .map((m) => getMonthName(+m.key))
          .join(', ')}
        onReset={() => selectedMonthChange([])}
      >
        {showFilter({ type: 'month' })}
      </BottomFilterItem>
      <BottomFilterItem
        title={t('components.bottomFilters.year')}
        selected={selectedYear
          .sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10))
          .map((y) => y.key)
          .join(', ')}
        onReset={() => selectedYearChange([])}
      >
        {showFilter({ type: 'years' })}
      </BottomFilterItem>
      {availableLocations?.length && (
        <BottomFilterItem title={t('components.bottomFilters.location')}>
          {showFilter({ type: 'years' })}
        </BottomFilterItem>
      )}
      {availableServices.length > 0 && <BottomFilterItem
        title={t('components.bottomFilters.service')}
        selected={selectedServices.map((s) => s.name).join(', ')}
        onReset={() => selectedServicesChange([])}
      >
        {showFilter({ type: 'services' })}
      </BottomFilterItem>}
      <BottomFilterItem
        title={t('components.bottomFilters.resource')}
        selected={selectedResources.map((r) => r.name).join(', ')}
        onReset={() => selectedResourcesChange([])}
      >
        {showFilter({ type: 'resources' })}
      </BottomFilterItem>
      {''/*showResetBtn && (
        <ButtonStyled theme={theme} variant="link" onClick={cleanFilters}>
          {t('components.bottomFilters.cleanFilters')}
        </ButtonStyled>
      )*/}
      {''/*
      <ButtonStyled theme={theme} variant="transparent" onClick={onBackClick}>
        {t('components.bottomFilters.bntBack')}
      </ButtonStyled>
      <ButtonStyled theme={theme} onClick={onApplyClick}>
        {t('components.bottomFilters.bntApply')}
      </ButtonStyled>*/}
    </Wrapper>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Wrapper = styled('div')({
  display: 'flex',
  padding: `${unit * 2}px 0`,
  flexWrap: 'wrap',
  gap: `${unit * 2}px`,
  borderTop: '2px solid #dddddd',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    '& > *:not(last-child)': {
      marginInlineEnd: unit * 3
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    '& > *:not(last-child)': {
      marginLeft: unit,
    },
    position: 'relative',
//    flexDirection: 'column-reverse',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const ButtonRow = styled('div')(showForMobile, flexCenter, {
  button: {
    minWidth: unit * 9,
  },
});

const ButtonStyled = styled(Button)({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    fontSize: unit * 2,
    order: -1,
  },
});
