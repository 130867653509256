import styled from '@emotion/styled';

import { smallText, unit } from '../styles';
import { ITheme } from '../data-layer/types';

const InputAlert = styled('span')(smallText, (props: { theme: ITheme }) => ({
  position: 'absolute',
  left: '50%',
  top: `calc(100% + ${unit * 0.5}px)`,
  color: props.theme.chevronColor,
  fontWeight: 400,
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
}));

export default InputAlert;
