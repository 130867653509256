import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { testPass, MIN_PASS_LENGTH, ClientContext } from '../utils';
import { mediumText, smallText, unit } from '../styles';
import { ITheme } from '../data-layer/types';

interface PasswordInfoProps {
  password: string;
}

export const PasswordInfo: React.FC<PasswordInfoProps> = ({ password = '' }: PasswordInfoProps) => {
  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  if (!password) return null;
  const hasWrongSymbols = !!password.replace(/[a-zA-Z\d`!@#$%^&*()_=+[\]{};:"|,.]/g, '').length;
  const width = `${(password.length * 100) / MIN_PASS_LENGTH}%`;
  let title = t('components.passwordInfo.strong.title');
  let text = t('components.passwordInfo.strong.text');
  let background = 'green';

  if (hasWrongSymbols) {
    title = t('components.passwordInfo.wrong.title');
    text = t('components.passwordInfo.wrong.text');
    background = 'red';
  } else if (password.length < MIN_PASS_LENGTH) {
    title = t('components.passwordInfo.short.title');
    text = t('components.passwordInfo.short.text', { passLen: MIN_PASS_LENGTH });
    background = 'red';
  } else if (testPass(password)) {
    title = t('components.passwordInfo.weak.title');
    text = t('components.passwordInfo.weak.text');
    background = 'red';
  }
  return (
    <Container theme={theme}>
      <Title>
        {title}
        <Line style={{ background, width }} />
      </Title>
      <Text>{text}</Text>
    </Container>
  );
};
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')((props: { theme: ITheme }) => ({
  position: 'absolute',
  bottom: `calc(100% + ${unit * 0.5}px)`,
  width: '100%',
  padding: `${unit * 1.5}px ${unit * 2}px`,
  background: props.theme.backgroundColor,
  borderRadius: unit,
  boxShadow: `3px 3px 6px 3px rgb(12, 6, 21, .9)`,
  zIndex: 1,
}));

const Title = styled('div')(mediumText);

const Text = styled('div')(smallText, {
  fontWeight: 500,
  opacity: 0.3,
});

const Line = styled('hr')({
  height: unit * 0.3,
  border: 'none',
  maxWidth: '100%',
  margin: `${unit}px 0`,
  transition: 'all 0.2s ease-in-out',
  [`[dir="rtl"] &`]: {
    marginLeft: 'auto',
  },
});
