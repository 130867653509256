import { gql } from 'apollo-boost';

export const GET_EHR_APPOINTMENT_RESULT = gql`
  query GetEHRAppointmentResult(
    $businessID: String!
    $clientId: String!
    $visitId: String!
    $user: String!
    $token: String!
    $ehrEndPoint: String!
  ) {
    getEHRAppointmentResult(
      businessID: $businessID
      clientId: $clientId
      visitId: $visitId
      user: $user
      token: $token
      ehrEndPoint: $ehrEndPoint
    ) @client {
      id
      patientId
      created
      start
      duration
      business {
        name
        location
      }
      doctor {
        name
        surname
      }
      anamnesis
      medicalExaminationResult
      diagnosis {
        id
        diagnosisText
        cd10 {
          code
          description
        }
        kind
        type
      }
      recommendations {
        id
        created
        title
        services {
          name
        }
        type
        status
        period {
          begin
          end
        }
        strictPeriod {
          begin
          end
        }
        preparations
        requiredPreparations
        appointmentResultId
      }
      appointmentId
      scheduledProcedures {
        id
        created
        title
        services {
          name
        }
        type
        status
        period {
          begin
          end
        }
        strictPeriod {
          begin
          end
        }
        preparations
        requiredPreparations
        appointmentResultId
      }
      prescriptions {
        id
        created
        recorderDoctor {
          name
          surname
        }
        dosageText
        reasonText
        validityPeriod {
          begin
          end
        }
        numberOfRepeats
        title
        medications {
          form
          amount
          expirationDate
        }
      }
      diagnosticReportIds
      reportInfo
      attachments {
        url
        file
      }
    }
  }
`;
