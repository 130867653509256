/* eslint-disable no-plusplus */
/**
 * Utils for phone number processing
 */
import { formatNumber, parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';
import { IPhoneData } from 'data-layer/types';

export const checkPhone = (phone: string, countryCode?: string): boolean => {
  const correctCountryCode = countryCode?.toUpperCase() as CountryCode;
  const parsedPhone = parsePhoneNumberFromString(phone, correctCountryCode);
  if (phone && !!parsedPhone && parsedPhone.country === correctCountryCode) {
    return parsedPhone.isValid();
  }

  return false;
};

export const getNationalPhone = (phoneString: string, countryCode: string): string => {
  const correctCountryCode = countryCode?.toUpperCase() as CountryCode;
  const parsedPhone = parsePhoneNumberFromString(phoneString, correctCountryCode);
  if (!parsedPhone || parsedPhone.country !== correctCountryCode) {
    return phoneString;
  }
  return formatNumber(parsedPhone.number, 'NATIONAL', {
    fromCountry: countryCode as CountryCode,
  });
};

export const getPhone = (phoneString: string, countryCode: string): IPhoneData => {
  const correctCountryCode = countryCode?.toUpperCase() as CountryCode;
  const parsedPhone = parsePhoneNumberFromString(phoneString, correctCountryCode);
  if (!parsedPhone || parsedPhone.country !== correctCountryCode) {
    return {
      country_code: '',
      area_code: '',
      number: '',
    };
  }
  const phone: string[] = formatNumber(parsedPhone.number, 'INTERNATIONAL', {
    fromCountry: countryCode as CountryCode,
  }).split(' ');
  let i = 0;
  if (!phone[0]) {
    i++;
  }
  const areaCode = phone[++i];
  let phoneNumber = '';
  for (let j = i + 1; j < phone.length; j++) {
    phoneNumber += phone[j];
  }
  const country_code = parsedPhone.countryCallingCode as string;

  return {
    country_code,
    area_code: areaCode,
    number: phoneNumber,
  };
};

export const getPhoneString = ({ country_code, area_code, number }: IPhoneData): string => {
  return `${country_code}${area_code}${number}`;
};
