/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import Modal from 'react-modal';
import { ClassNames, css } from '@emotion/core';

import ModalCloseButton from './ModalCloseButton';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';
import { unit, size, breakpointLarge, flexCenter } from '../styles';
import { ClientContext } from '../utils';
import { transparentize } from 'polished';

Modal.setAppElement('#root');

const overlayStyles = css(flexCenter, {
  backdropFilter: `blur(6px)`,
  zIndex: 10,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'auto',
  [`@media screen and (max-height: ${size.layoutMaxHeightDesktop}px)`]: {
    alignItems: 'flex-start',
  },
});

const contentStyles = css({
  border: 'none',
  maxWidth: size.layoutMaxWidthDesktop,
  borderRadius: 4,
  outline: 'none',
  padding: unit * 2,
});

const dialogContentStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *:not(:last-child)': {
    marginBottom: unit * 3,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    maxWidth: `calc(100vw - ${unit * 3}px)`,
    justifyContent: 'center',
  },
  [`@media screen and (max-height: ${size.layoutMaxHeightDesktop}px)`]: {
    minHeight: `calc(100vh - ${unit * 5}px)`,
  },
});

type modalVariant = 'widget' | 'dialog';

const getStyle = (variant: modalVariant) => {
  switch (variant) {
    case 'dialog':
      return css(contentStyles, dialogContentStyles);
    default:
      return contentStyles;
  }
};

export interface IModalProps extends Modal.Props {
  children?: React.ReactNode;
}

interface IModalBasicProps extends IModalProps {
  isOpen: boolean;
  variant: modalVariant;
  id?: string;
  children?: React.ReactNode;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
}

const ModalBasic: React.FC<IModalBasicProps> = ({
  isOpen,
  variant,
  id = undefined,
  onAfterOpen = undefined,
  onRequestClose = undefined,
  children = undefined,
}: IModalBasicProps) => {
  const modalStyles = getStyle(variant);
  const { theme } = useContext(ClientContext);
  const styles = css(flexCenter, overlayStyles, {
    background: transparentize(0.6, theme.backdropColor),
  });
  return (
    <ClassNames>
      {({ css: _ }) => (
        <Modal
          isOpen={isOpen}
          id={id}
          className={_(modalStyles)}
          overlayClassName={_(styles)}
          onAfterOpen={onAfterOpen}
          onRequestClose={onRequestClose}
          shouldCloseOnEsc={false}
        >
          {variant === 'widget' && (
            <ModalCloseButton onClick={onRequestClose}>
              <IconDelete />
            </ModalCloseButton>
          )}
          {children}
        </Modal>
      )}
    </ClassNames>
  );
};

export default ModalBasic;
