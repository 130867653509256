import React, { Component } from 'react';
import styled from '@emotion/styled';

import Heading from './Heading';
import AuthForm from './AuthForm';
import Button from './button';
import Input, { iconType } from './Input';
import InputAlert from './InputAlert';
import { breakpointLarge, mediumText, unit, contentCSS } from '../styles';
import * as LoginTypes from '../pages/__graphql__/Login';
import { withTranslation, WithTranslation } from 'react-i18next';
import PageContainer from './pageContainer';
import { PhoneInputComponent as PhoneInput } from './PhoneInput';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { ClientContext } from '../utils/ClientContext';

interface LoginPasswordFormProps {
  login: (a: { variables: LoginTypes.LoginVariables }) => void;
  phone: string;
  businessInfo: GetBusinessInfo_getBusinessInfo;
  onPhoneChange: (phone: string, country: string) => void;
  switchAuth: () => void;
  error?: boolean;
  country?: string;
}
interface LoginPasswordFormState {
  phone: string;
  country: string;
  phoneValid: boolean;
  password: string;
  passwordVisible: boolean;
  error: boolean;
}

class LoginPasswordForm extends Component<
  LoginPasswordFormProps & WithTranslation,
  LoginPasswordFormState
> {
  constructor(props: LoginPasswordFormProps & WithTranslation, context?: unknown) {
    super(props, context);
    const { phone, country } = this.props;
    this.state = {
      phone,
      country: country || '',
      password: '',
      passwordVisible: false,
      error: !!props.error,
      phoneValid: true,
    };
  }

  onChangePhone = (phone: string, phoneValid: boolean, countryCode?: string) => {
    const { country } = this.state;
    const { onPhoneChange } = this.props;
    if (countryCode !== country) {
      onPhoneChange(phone, countryCode || '');
    }
    // eslint-disable-next-line react/no-unused-state
    this.setState({ phone, country: countryCode || '', phoneValid, error: false });
  };

  onChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = (event.target as HTMLInputElement).value;
    event.target.scrollIntoView();
    this.setState({ password, error: false });
  };

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { phone, password, country } = this.state;
    const { login } = this.props;
    this.updatePhone();
    event.preventDefault();
    login({
      variables: {
        phone,
        password,
        country,
      },
    });
  };

  updatePhone = (): void => {
    const { phone, country } = this.state;
    const { onPhoneChange } = this.props;
    onPhoneChange(phone, country);
  };

  onChangeView = () => {
    this.updatePhone();
    const { switchAuth } = this.props;

    return switchAuth();
  };

  onTogglePasswordVisibility = () => {
    const { passwordVisible } = this.state;
    this.setState({ passwordVisible: !passwordVisible });
  };

  renderPasswordRow = () => {
    const { passwordVisible, password, error, phoneValid } = this.state;
    const { t } = this.props;
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const isPassInvalid = !password.length;
    const icon: iconType = passwordVisible ? 'visible' : 'hidden';

    return (
      <PasswordRow>
        <Input
          required
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          placeholder=""
          value={password}
          icon={error ? 'error' : icon}
          autoComplete="current-password"
          data-testid="password-input"
          onChange={this.onChangePass}
          onIconClick={this.onTogglePasswordVisibility}
        >
          {error && <InputAlert theme={theme}>{t('components.auth.error')}</InputAlert>}
        </Input>
        <SubmitButton theme={theme} type="submit" disabled={isPassInvalid || !phoneValid}>
          {t('components.auth.submitButtonLabel')}
        </SubmitButton>
      </PasswordRow>
    );
  };

  render() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { phone, error, phoneValid } = this.state;
    const { t, businessInfo, country } = this.props;
    let icon;
    if (phone.length) {
      icon = error || !phoneValid ? 'error' : 'success';
    }
    return (
      <PageContainerStyled title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
        <StyledHeading>{t('components.auth.phoneNumber')}</StyledHeading>
        <AuthForm onSubmit={this.onSubmit}>
          <PhoneInput
            phone={phone}
            country={country}
            icon={icon as iconType}
            onPhoneChange={this.onChangePhone}
            businessInfo={businessInfo}
          >
            {!phoneValid && (
              <InputAlert theme={theme}>{t('components.auth.errorPhone')}</InputAlert>
            )}
          </PhoneInput>
          <Label>{t('components.auth.password')}</Label>
          {this.renderPasswordRow()}
          <Button theme={theme} variant="link" onClick={this.onChangeView}>
            {t('components.auth.forgetPasswordButtonLabel')}
          </Button>
        </AuthForm>
      </PageContainerStyled>
    );
  }
}

LoginPasswordForm.contextType = ClientContext;

export default withTranslation()(LoginPasswordForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
 
const PageContainerStyled = styled(PageContainer)({
  overflow: 'auto'
});

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});

const PasswordRow = styled('div')({
  position: 'relative',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    padding: `0 ${unit * 9}px`,
  },
});

const Label = styled('div')(mediumText, {
  marginTop: unit * 3,
});

const SubmitButton = styled(Button)({
  minWidth: 'auto',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [`[dir="ltr"] &`]: {
      right: 0,
    },
    [`[dir="rtl"] &`]: {
      left: 0,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    margin: `${unit * 2.5}px auto 0`,
  },
});
