import { gql } from 'apollo-boost';

export const GET_EHR_DIAGNOSTIC_RESULT = gql`
  query GetEHRDiagnosticResult( $businessID: String!
    $clientId: String!
    $visitId: String!
    $user: String!
    $token: String!
    $ehrEndPoint: String!
  ) {
    getEHRDiagnosticResult(
      businessID: $businessID
      clientId: $clientId
      visitId: $visitId
      user: $user
      token: $token
      ehrEndPoint: $ehrEndPoint
    ) @client {
      id
      status
      business {
        id
        name
        location
        networkId
      }
      patientId
      patient {
        id
        surname
        name
        middleName
        phones
        email
        gender
        date
        medcardNumber
      }
      type
      effectivePeriod {
        begin
        end
      }
      issuedDate
      result {
        id
        createdDate
        type
        observationKey
        effectivePeriod {
          begin
          end
        }
        issuedDate
        performerDoctor {
          id
          name
          surname
        }
        performerBusiness {
          name
          location
        }
        value {
          serializedValue
          code
          value
        }
        note
        interpretation
        ranges {
          low
          high
          type
          age {
            begin
            end
          }
          text
        }
        components {
          type
          value {
            serializedValue
            code
            value
          }
          interpretation
          ranges {
            low
            high
            type
            age {
              begin
              end
            }
            text
          }
        }
      }
      resultInterpreter {
        id
        name
        surname
      }
      resultInterpretation
      imagineMedia
      attachments {
        url
        file
      }
      services {
        id
        name
      }
      category
    }
  }
`;
