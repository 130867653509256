import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { lighten } from 'polished';

import { ClientContext } from '../utils';
import { breakpointLarge, unit } from '../styles';
import ScrollBlock from './ScrollBlock';

const InsuranceInfoTable: React.FC = () => {
  const { t } = useTranslation();
  const { theme, clientInfo } = useContext(ClientContext);
  const { contractAttachments = [] } = clientInfo;

  const Container = styled('div')({
    paddingTop: unit * 2,
    paddingBottom: unit,
    [`[dir="ltr"] &`]: {
      paddingRight: unit * 2,
    },
    [`[dir="rtl"] &`]: {
      paddingLeft: unit * 2,
    },
    '& .title-mobile': {
      fontWeight: 900,
      fontSize: 14,
      lineHeight: 1.2142857,
      paddingTop: unit,
      paddingBottom: unit * 0.5,
      [`@media screen and (min-width: ${breakpointLarge}px)`]: {
        display: 'none',
      },
    },
    '& .header': {
      display: 'flex',
      borderBottom: `1px solid ${theme.mainColor}`,
      '& > *': {
        fontWeight: 900,
        fontSize: 14,
        lineHeight: `${unit * 3}px`,
      },
      [`@media screen and (max-width: ${breakpointLarge}px)`]: {
        display: 'none',
      },
    },
    '& .content': {
      height: '100vh',
      maxHeight: unit * 20,
    },
    '& .row': {
      lineHeight: `${unit * 2.2}px`,
      borderBottom: `1px solid ${lighten(0.3, theme.mainColor)}`,
      [`@media screen and (min-width: ${breakpointLarge}px)`]: {
        display: 'flex',
      },
    },
    '& .number': {
      flexGrow: 0,
      flexBasis: unit * 15,
    },
    '& .name, & .date': {
      [`@media screen and (min-width: ${breakpointLarge}px)`]: {
        width: `calc(100% - ${unit * 15 * 2}px)`,
      },
    },
  });

  if (!contractAttachments.length) {
    return null;
  }

  const [{ attachmentNumber, attachmentName, dateBegin, dateEnd }] = contractAttachments;
  return (
    <Container>
      <div className="header">
        {attachmentNumber && (
          <div className="number">{t('components.profileForm.insuranceNumber')}</div>
        )}
        {attachmentName && (
          <div className="name">{t('components.profileForm.insuranceProgram')}</div>
        )}
        {dateBegin && dateEnd && (
          <div className="date">{t('components.profileForm.insuranceDates')}</div>
        )}
      </div>
      <div className="content">
        <ScrollBlock>
          {contractAttachments.map((item) => (
            <div className="row" key={item.attachmentNumber}>
              {attachmentNumber && (
                <div className="number">
                  <div className="title-mobile">{t('components.profileForm.insuranceNumber')}</div>
                  {item.attachmentNumber}
                </div>
              )}
              {attachmentName && (
                <div className="name">
                  <div className="title-mobile">{t('components.profileForm.insuranceProgram')}</div>
                  {item.attachmentName}
                </div>
              )}
              {dateBegin && dateEnd && (
                <div className="date">
                  <div className="title-mobile">{t('components.profileForm.insuranceDates')}</div>
                  {item.dateBegin} - {item.dateEnd}
                </div>
              )}
            </div>
          ))}
        </ScrollBlock>
      </div>
    </Container>
  );
};

export default InsuranceInfoTable;
