import {
  ApolloClient,
  HttpLink,
  NormalizedCacheObject,
  InMemoryCache,
  defaultDataIdFromObject,
  IdGetterObj,
} from 'apollo-boost';
import { persistCache } from 'apollo-cache-persist';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { resolvers } from './resolvers';
import { cacheRedirects } from './cacheRedirects';
import { urlManager } from 'utils/urlManager';

export type Client = ApolloClient<NormalizedCacheObject>;
interface CustomIdGetterObj extends IdGetterObj {
  shortId?: string;
}
export const createApolloClient = async (): Promise<Client> => {
  const cache = new InMemoryCache({
    cacheRedirects,
    dataIdFromObject: (object: CustomIdGetterObj) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (object.__typename) {
        case 'AppointmentClient':
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `AppointmentClient:${object.shortId}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  });
  const storage = localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>;
  await persistCache({ cache, storage });
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: new HttpLink({ uri: 'http://localhost:8080/no-graphql' }),
    resolvers,
    // it brings Apollo DevTools in Chrome to life since it requires a schema,
    // but we don't have a GraphQL server:
    // https://github.com/apollographql/apollo-client-devtools/issues/228
    // TODO: provide the full schema in development here for our schema entities
    // to show up in the Apollo DevTools playground
    // eslint-disable-next-line
    typeDefs: {} as any,
  });

  const initData = {
    businessID: urlManager.getBusinessId() || '',
    networkID: urlManager.getNetworkId() || '',
  };
  cache.writeData({ data: initData });
  return client;
};
