import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';

import UserPic from './UserPic';
import ScrollBlock from './ScrollBlock';
import { breakpointLarge, hideForMobile, mediumText, smallText, unit } from '../styles';

import { ClientContext, constants, dateFormatFunc, getMultilineTranslation } from 'utils';
import { GetEHRAppointmentResult_getEHRAppointmentResult } from 'data-layer/queries/__graphql__/GetEHRAppointmentResult';
import { EHR } from 'medme-ehr-js-sdk';
import { IAppointmentResultMessage } from 'medme-ehr-js-sdk/dist/es5/interfaces';
import { GetEHRAppointments_getPatientAppointments_appointments } from 'data-layer/queries/__graphql__/GetEHRAppointments';

interface EHRAppointmentInfoBlockProps {
  appointmentData: GetEHRAppointmentResult_getEHRAppointmentResult;
  ehrAppointment?: GetEHRAppointments_getPatientAppointments_appointments;
}

export const EHRAppointmentInfoBlock: React.FC<EHRAppointmentInfoBlockProps> = ({
  appointmentData,
  ehrAppointment
}: EHRAppointmentInfoBlockProps) => {
  const { doctor, start, business } = appointmentData;
  const { lang } = useContext(ClientContext);
  const formatter = new EHR.Formatters.SimpleTextFormatterV2(
    EHR.Formatters.SimpleTextFormatterV2.LOCALIZE['ru-ru'],
    dateFormatFunc({ format: 'D MMMM  YYYY' }),
  );
  const result: IAppointmentResultMessage = appointmentData
  const results = formatter.appointmentResult(result, '\r\n');
  const startDate = moment.utc(start).calendar(null, { sameElse: lang.dateFormat });

  const getBusinessInfo = () => {
    const { name, location } = business;
    return `${name} ${location}`;
  };

  const resourceName = useMemo(() => {
    if (doctor) {
      const { name, surname } = doctor;
      return `${surname} ${name}`;
    }

    return '';
  }, [doctor])

  const title = () => {
    let serviceName = ''
    if (ehrAppointment) {
      serviceName = ehrAppointment ? ehrAppointment.services?.[0]?.name || '' : ''
    }

    return `${serviceName} ${resourceName}`.trim();
  };

  const resourceImage = () => {
    const src = `${constants.defaultResourceImage}`;
    return <UserPicStyled size={80} alt={resourceName} src={src} />;
  };

  return (
    <>
      <ScrollBlock>
        <Content>
          <Date>{startDate}</Date>
          <Location>{getBusinessInfo()}</Location>
          <Resource>
            {resourceImage()}
            {title()}
          </Resource>
          {getMultilineTranslation(results)}
          {!!appointmentData.reportInfo.length && getMultilineTranslation(appointmentData.reportInfo)}
        </Content>
      </ScrollBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Content = styled('div')({
  width: '100%',
  ['[dir="ltr"] &']: {
    padding: `0 ${unit * 2}px ${unit * 5}px ${unit * 19.2}px`,
  },
  ['[dir="rtl"] &']: {
    padding: `0 ${unit * 19.2}px ${unit * 5}px ${unit * 2}px`,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    padding: `0 !important`,
  },
});

const Date = styled('div')(mediumText, {
  marginBottom: unit * 4,
});

const Resource = styled('div')(mediumText, {
  position: 'relative',
  marginBottom: unit * 4,
});

const Location = styled('div')(smallText, {
  fontWeight: 500,
});

const UserPicStyled = styled(UserPic)(hideForMobile, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  ['[dir="ltr"] &']: {
    left: `-${unit * 10}px`,
  },
  ['[dir="rtl"] &']: {
    right: `-${unit * 10}px`,
  },
});
