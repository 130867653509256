import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { unit } from '../styles';
import { ITheme, TStartRecordVariant } from '../data-layer/types';
import { ClientContext } from '../utils';

interface SmallStartRecordItemProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: React.ReactNode
}

const SmallStartRecordItem = (props: SmallStartRecordItemProps): JSX.Element => {
  // eslint-disable-next-line react/prop-types
  const { title, children, onClick } = props;
  const { theme } = useContext(ClientContext);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container onClick={onClick}>
      {children}
      {/*['big', 'card'].includes(variant) && */<Title theme={theme}>{title}</Title>}
    </Container>
  );
};

export default SmallStartRecordItem;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  maxWidth: unit * 102,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.214285,
  svg: {
    width: unit * 4,
    height: unit * 4,
    [`[dir="ltr"] &`]: {
      marginRight: unit * 1.5,
    },
    [`[dir="rtl"] &`]: {
      marginLeft: unit * 1.5,
    },
  },
});

const Title = styled('h3')((props: { theme: ITheme }) => ({
  color: props.theme.mainColor,
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
}));
