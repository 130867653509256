import { gql } from 'apollo-boost';

export const GET_EHR_APPOINTMENTS_COUNT = gql`
  query GetEHRAppointmentsCount(
    $businessID: String!
    $clientId: String!
    $patientId: String!
    $user: String!
    $token: String!
    $ehrEndPoint: String!
  ) {
    getPatientResultsCount(
      businessID: $businessID
      clientId: $clientId
      patientId: $patientId
      user: $user
      token: $token
      ehrEndPoint: $ehrEndPoint
    ) @client {
      count
      support
    }
  }
`;
