import React, { Component } from 'react';
import styled from '@emotion/styled';
import { withTranslation, WithTranslation } from 'react-i18next';

import AuthForm from './AuthForm';
import Heading from './Heading';
import Button from './button';
import Input from './Input';
import InputAlert from './InputAlert';
import { PasswordInfo } from '.';
import PageContainer from './pageContainer';
import { smallText, unit } from '../styles';
import { testPass } from '../utils';
import { ClientContext } from '../utils/ClientContext';

interface SetNewPasswordFormProps {
  changePassword: (password: string) => void;
  error?: boolean;
}
interface SetNewPasswordFormState {
  password: string;
  passwordHidden: boolean;
}

class SetNewPasswordForm extends Component<
  SetNewPasswordFormProps & WithTranslation,
  SetNewPasswordFormState
> {
  constructor(props: SetNewPasswordFormProps & WithTranslation, context?: unknown) {
    super(props, context);
    this.state = { password: '', passwordHidden: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onIconClick = (_: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const { passwordHidden } = this.state;

    this.setState({ passwordHidden: !passwordHidden });
  };

  onChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = (event.target as HTMLInputElement).value;
    this.setState({ password });
  };

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { password } = this.state;
    const { changePassword } = this.props;
    event.preventDefault();
    changePassword(password);
  };

  render() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { t, error } = this.props;
    const { password, passwordHidden } = this.state;
    const opacity = +testPass(password);
    return (
      <PageContainer
        title={t('title.changePass')}
        layoutClassName="login"
        contentCSS={{ justifyContent: 'center' }}
      >
        <StyledHeading>{t('components.newPassword.label')}</StyledHeading>
        <AuthForm onSubmit={this.onSubmit}>
          <StyledInput
            required
            type={passwordHidden ? 'password' : 'text'}
            name="password"
            placeholder=""
            value={password}
            icon={passwordHidden ? 'hidden' : 'visible'}
            autoComplete="new-password"
            data-testid="password-input"
            onChange={this.onChangePass}
            onIconClick={this.onIconClick}
          >
            {error && <InputAlert theme={theme}>{t('components.newPassword.error')}</InputAlert>}
            <PasswordInfo password={password} />
          </StyledInput>
          <StyledLabel style={{ opacity }}>{t('components.newPassword.info')}</StyledLabel>
          <Button theme={theme} type="submit" disabled={testPass(password)}>
            {t('components.newPassword.submit')}
          </Button>
        </AuthForm>
      </PageContainer>
    );
  }
}

SetNewPasswordForm.contextType = ClientContext;

export default withTranslation()(SetNewPasswordForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});

const StyledInput = styled(Input)({
  maxWidth: unit * 41,
});

const StyledLabel = styled('span')(smallText, {
  fontWeight: 500,
  textAlign: 'center',
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease-in-out',
});
