import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';

import {
  AppointmentOptions as AppointmentOptionsData,
  AppointmentOptionsVariables,
} from './__graphql__/AppointmentOptions';

// components
import JsonView from 'react-json-view';

const GET_APPOINTMENT_OPTIONS = gql`
  query AppointmentOptions($businessId: ID!) {
    appointmentOptions(businessId: $businessId) @client {
      businessId
      availableCategories {
        id
        taxonomyType
      }
      availableServices {
        id
        taxonomyType
      }
      availableResources {
        id
        name
      }
    }
  }
`;

export const AppointmentOptions = (): JSX.Element => {
  const businessId = '4000000005705';

  const appointmentOptionsQueryResult = useQuery<
    AppointmentOptionsData,
    AppointmentOptionsVariables
  >(GET_APPOINTMENT_OPTIONS, {
    variables: { businessId },
  });

  return (
    <JsonView
      name="appointmentOptionsQueryResult"
      src={appointmentOptionsQueryResult.data || {}}
      collapsed={1}
    />
  );
};
