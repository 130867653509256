/* eslint-disable react/require-default-props */
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { flexCenter, fullSize } from '../styles';
import { useTranslation } from 'react-i18next';
import { ITheme } from '../data-layer/types';
import { ClientContext } from '../utils';

const DEFAULT_SIZE = 42;

interface UserPicProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  src?: string;
  alt?: string;
  className?: string;
  textPlaceholder?: boolean;
  onClick?: () => void;
}

const UserPic: React.FC<UserPicProps> = ({
  size = DEFAULT_SIZE,
  alt = '',
  src = '',
  className,
  textPlaceholder,
  onClick,
}: UserPicProps) => {
  const [imgSrc, setImgSrc] = useState(src);
  const { t } = useTranslation();
  const { theme } = useContext(ClientContext);
  useEffect(() => {
    setImgSrc(src);
  }, [src]);
  const renderContent = () => {
    if (imgSrc) return <Img src={imgSrc} alt={alt} onError={() => setImgSrc('')} />;
    if (textPlaceholder)
      return <Placeholder>{t('components.profileInfo.changePhotoModalLabel')}</Placeholder>;
    return (
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <rect width="200" height="200" fill={theme.passiveColor} />
        <path
          d="M100,113.195c-31.245,0-47.5,18.472-47.5,29.028V147.5h95v-5.278C147.5,131.666,131.245,113.195,100,113.195ZM100,52.5A26.389,26.389,0,1,1,73.611,78.889,26.389,26.389,0,0,1,100,52.5h0Z"
          fill={theme.mainColor}
        />
      </Svg>
    );
  };
  return (
    <div className={className} onClick={onClick}
      style={{
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'rgba(12, 6, 21, 0.12)',
        flexShrink: 0,
        width: size || 'initial',
        height: size || 'initial'
      }}
    >
      {renderContent()}
    </div>
  );
};

export default UserPic;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Img = styled('img')(fullSize, {
  position: 'absolute',
  top: 0,
  left: 0,
  objectFit: 'cover',
});

const Svg = Img.withComponent('svg');

const Placeholder = styled('span')(flexCenter, fullSize, (props: { theme: ITheme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: props.theme.mainColor,
  whiteSpace: 'pre',
  textAlign: 'center',
  cursor: 'pointer',
}));
