import moment from 'moment';
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import { paths } from '../utils/routing';
import { smallText, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { GetEHRDiagnostics_getEHRDiagnostics_diagnostics } from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { ReactComponent as IconLab } from '../assets/icon-lab.svg';
import { ITheme } from '../data-layer/types';
import { darken } from 'polished';
import { ClientContext } from '../utils';

interface EHRDiagnosticBlockProps {
  appointmentData: GetEHRDiagnostics_getEHRDiagnostics_diagnostics;
}

type cardVariant = 'past' | 'future' | undefined;

export const EHRDiagnosticBlock: React.FC<EHRDiagnosticBlockProps> = React.memo(
  function EHRDiagnosticBlock (props: EHRDiagnosticBlockProps) {
    const { appointmentData } = props;
    const { id, services, resultInterpreter, issuedDate } = appointmentData;
    const { theme } = useContext(ClientContext);
    const start = (issuedDate as string) || '';
    const queryString = urlManager.getQueryString();
    const cardVariant = 'past';
    const startTime = start
      ? moment.utc(start).calendar(null, { sameElse: 'D MMMM YYYY' })
      : '';
    function getWorkerName () {
      if (resultInterpreter?.length) {
        return resultInterpreter
          .map((doctor) => {
            if (doctor) {
              const { name, surname } = doctor;
              return `${surname} ${name}`;
            }
            return '';
          })
          .join(',');
      }

      return '';
    }

    function getTitle () {
      let title = getWorkerName();
      if (services?.length && services?.[0]?.name) {
        title = services?.[0]?.name;
      }

      return `${title}`;
    }

    return (
      <Card theme={theme} variant={cardVariant} title={getTitle()}>
        <StyledLink to={`${paths.ehrDiagnosticPath(id)}?${queryString}`}>
          <IconLabStyled theme={theme} />
          <CardTitle theme={theme}>{getTitle()}</CardTitle>
          <CardDate>{startTime}</CardDate>
        </StyledLink>
      </Card>
    );
  }
);

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: cardVariant;
  theme: ITheme;
}

const Card = styled('div')(({ variant, theme }: ICardProps) => {
  let background;
  switch (variant) {
    case 'past':
      background = theme.pastItemBackgroundColor;
      break;
    case 'future':
      background = theme.telemedItemBackgroundColor;
      break;
    default:
      background = theme.itemBackgroundColor;
      break;
  }
  return {
    background,
    position: 'relative',
    boxShadow: '3px 3px 6px 3px rgb(12, 6, 21, .09)',
    borderRadius: unit * 1.5,
    padding: `${unit * 2}px ${unit * 2}px ${unit * 2.4}px`,
  };
});

const CardTitle = styled('h3')((props: { theme: ITheme }) => ({
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  color: props.theme.textColor,
  overflow: 'hidden',
  // whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ['[dir="ltr"] &']: {
    margin: `0 ${unit * 2}px ${unit * 2}px 0`,
  },
  ['[dir="rtl"] &']: {
    margin: `0 0 ${unit * 2}px ${unit * 2}px`,
  },
}));

const CardDate = styled('div')(smallText, {
  fontWeight: 500,
});

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const IconLabStyled = styled(IconLab)((props: { theme: ITheme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  ['[dir="ltr"] &']: {
    right: unit * 2,
  },
  ['[dir="rtl"] &']: {
    left: unit * 2,
  },
  path: {
    fill: props.theme.textColor ? darken(0.1, props.theme.textColor) : '#000',
  },
}));
